import { useGetSeriesList } from "Hooks";
import { SeriesCard } from "Components";
import { Get_Series_List_getSeriesList } from "Types";
import { useEffect, useState } from "react";

export const SeriesList = () => {
  const [seriesList, setSeriesList] = useState<
    Get_Series_List_getSeriesList["series"]
  >([]);

  const { getSeriesList, data } = useGetSeriesList();

  useEffect(() => {
    getSeriesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data && data.getSeriesList) {
      setSeriesList(data.getSeriesList.series);
    }
  }, [data]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "25px",
          alignItems: "center",
          justifyContent: "center",
          padding: "50px",
          minHeight: "500px",
        }}
      >
        {seriesList &&
          seriesList.map(series => (
            <SeriesCard
              {...series}
              seriesEpisodesLength={series.seriesEpisodes?.length}
            />
          ))}
      </div>
    </>
  );
};
