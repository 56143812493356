import {
  Navbar,
  GeneralPageHeader,
  ReferralProgramAd,
  Sitemap,
  ZohoNewsletterSignUpForm,
} from "Components";
import React from "react";

export const NewsletterPage: React.FC = () => (
  <>
    <Navbar />
    <GeneralPageHeader
      mainHeading="Newsletter"
      subHeading="The Reformed Dissenters"
    />
    <div style={{ backgroundColor: "white" }}>
      <ZohoNewsletterSignUpForm />
      <div style={{ marginBottom: "60px", marginTop: "-20px" }}>
        <ReferralProgramAd />
      </div>
    </div>
    <Sitemap />
  </>
);
