import styled from "styled-components";
import { StandardGrey } from "../colors";
import Input from "@mui/material/Input";

export const SectionWrapper = styled.div`
  margin-top: 50px;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: ${StandardGrey};
`;

export const StyledInput = styled(Input)`
  border-radius: 8px;
  padding: 6px;
  background-color: white;
  padding-left: 10px;
  height: 40px;
  border-bottom: none;
  font-family: "Roboto";
  && {
    font-size: 18px;
  }
`;

export const FormHeading = styled.h2`
  font-family: "Roboto";
  font-size: 18px;
  text-align: center;
  margin: auto;
  margin-bottom: 20px;
  font-weight: normal;
`;

export const InputError = styled.div`
  color: red;
  margin-top: 10px;
  letter-spacing: 0.4px;
  font-family: "Roboto";
  font-size: 15px;
  padding-top: 16px;
  max-width: 300px;
  margin: auto;
`;
