import {
  SectionWrapper,
  SectionHeader,
  InfoPropertyWrapper,
  InfoProperty,
} from "./elements";

interface Props {
  episodeNumber: number;
  episodeType: string;
  publishDate: string;
}

export const EpisodeInfo: React.FC<Props> = ({
  episodeNumber,
  episodeType,
  publishDate,
}) => (
  <SectionWrapper>
    <SectionHeader>Episode Information</SectionHeader>
    <InfoPropertyWrapper>
      <InfoProperty>Episode number: </InfoProperty>
      <div>{episodeNumber}</div>
    </InfoPropertyWrapper>
    <InfoPropertyWrapper>
      <InfoProperty>Episode type: </InfoProperty>
      <div>{episodeType}</div>
    </InfoPropertyWrapper>
    <InfoPropertyWrapper>
      <InfoProperty>Published on: </InfoProperty>
      <div>{publishDate}</div>
    </InfoPropertyWrapper>
  </SectionWrapper>
);
