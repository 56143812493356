import React from "react";
import { Button } from "Components";
import { FormField } from "./elements";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputError } from "Components/FormComponents";
import CircularProgress from "@mui/material/CircularProgress";

const validationSchema = Yup.object({
  name: Yup.string().required("Please enter your name"),
  email: Yup.string()
    .email("Invalid email address")
    .required("You must enter an email address"),
  password: Yup.string().required("Please enter your password"),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please reenter your password"),
});

export interface HandleRegArgs {
  name: string;
  email: string;
  password: string;
  passwordConfirm: string;
}

interface Props {
  handleRegistration(args: HandleRegArgs): any;
  loading: boolean;
  setLoading: React.Dispatch<boolean>;
  longFieldsWidth?: boolean;
}

export const RegisterFields: React.FC<Props> = ({
  handleRegistration,
  loading,
  setLoading,
  longFieldsWidth = false,
}) => {
  const {
    handleChange,
    values: { name, email, password, passwordConfirm },
    errors,
    handleBlur,
    touched,
    setTouched,
    validateForm,
  } = useFormik({
    initialValues: { email: "", name: "", password: "", passwordConfirm: "" },
    validationSchema,
    onSubmit: () => undefined,
  });

  const handleFormSubmit = () => {
    setTouched({
      name: true,
      email: true,
      password: true,
      passwordConfirm: true,
    });
    (async () => {
      const formErrors = await validateForm();
      if (
        !formErrors.name &&
        !formErrors.email &&
        !formErrors.password &&
        !formErrors.passwordConfirm
      ) {
        setLoading(true);
        handleRegistration({ name, email, password, passwordConfirm });
      }
    })();
  };

  return (
    <>
      <FormField
        longWidth={longFieldsWidth}
        name="name"
        placeholder="Name"
        disableUnderline
        value={name}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <br />
      {touched.name && errors.name && <InputError>{errors.name}</InputError>}
      <FormField
        longWidth={longFieldsWidth}
        name="email"
        placeholder="Email"
        disableUnderline
        value={email}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <br />
      {touched.email && errors.email && <InputError>{errors.email}</InputError>}
      <FormField
        longWidth={longFieldsWidth}
        name="password"
        placeholder="Password"
        disableUnderline
        type="password"
        value={password}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <br />
      {touched.password && errors.password && (
        <InputError>{errors.password}</InputError>
      )}
      <FormField
        longWidth={longFieldsWidth}
        name="passwordConfirm"
        placeholder="Confirm password"
        disableUnderline
        type="password"
        value={passwordConfirm}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <br />
      {touched.passwordConfirm && errors.passwordConfirm && (
        <InputError>{errors.passwordConfirm}</InputError>
      )}
      <Button style={{ marginTop: "20px" }} onClick={handleFormSubmit}>
        {loading ? (
          <CircularProgress style={{ color: "white" }} size={20} />
        ) : (
          "Register"
        )}
      </Button>
    </>
  );
};
