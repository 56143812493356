import React from "react";
import styled from "styled-components";
import { Button, Navbar, Sitemap } from "Components";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useLocation } from "react-router-dom";

const Header = styled.div`
  font-size: 80px;
`;

const SubHeader = styled.div`
  font-size: 30px;
`;

const GoBackText = styled.div`
  margin-top: 50px;
  font-size: 20px;
`;

const goBackOnePage = () => {
  window.history.back();
};

export const FourOhFourPage: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <>
      <Navbar />
      <div
        style={{
          textAlign: "center",
          paddingTop: "130px",
          fontFamily: "Roboto",
        }}
      >
        <Header>404</Header>
        <SubHeader>
          Oops! We couldn't find a page matching: {pathname}
        </SubHeader>
        <GoBackText>Want to go back to where you were?</GoBackText>
        <Button
          style={{ marginTop: "20px" }}
          kind="filled"
          onClick={goBackOnePage}
        >
          <ArrowBackIosNewIcon /> Go Back
        </Button>
      </div>
      <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
        <Sitemap />
      </div>
    </>
  );
};
