import { DarkGrey } from "../colors";
import React from "react";
import styled from "styled-components";
import { Link } from "Components";
import gabLogo from "Images/gab-logo.png";
import gettrLogoWhite from "Images/gettr-logo-white.png";
import Box from "@mui/material/Box";

const Wrapper = styled.div`
  width: 100%;
  background-color: ${DarkGrey};
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
`;

const LinksWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: auto;
  flex-wrap: wrap;
`;

const SitemapItem = styled.div`
  color: white;
  font-size: 14px;
  font-family: "Roboto";
  display: flex;
`;

export const Sitemap: React.FC = () => (
  <Wrapper>
    <LinksWrapper maxWidth={{ xs: "350px", md: "470px" }}>
      <Link to="/">
        <SitemapItem>Home</SitemapItem>
      </Link>
      <Link to="/about">
        <SitemapItem>About The Reformed Dissenters</SitemapItem>
      </Link>
      <Link to="/refer">
        <SitemapItem>Refer a Friend</SitemapItem>
      </Link>
      <a
        href="https://thereformeddissenters.myshopify.com/"
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none" }}
      >
        <SitemapItem>Merch Store</SitemapItem>
      </a>
      <Link to="/newsletter">
        <SitemapItem>Join our Newsletter</SitemapItem>
      </Link>
      <Link to="/episodes">
        <SitemapItem>Show Episodes</SitemapItem>
      </Link>
      <Link to="/series-library">
        <SitemapItem>Series Library</SitemapItem>
      </Link>
    </LinksWrapper>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gap: "15px",
        marginTop: "40px",
        marginBottom: "35px",
      }}
    >
      <a target="_blank" href="https://gab.com/trdshow" rel="noreferrer">
        <img src={gabLogo} height="25" width="40" alt="Gab Logo" />
      </a>
      <a
        target="_blank"
        href="https://www.gettr.com/user/trdshow"
        style={{ marginTop: "-5px" }}
        rel="noreferrer"
      >
        <img src={gettrLogoWhite} height="24" width="50" alt="Gettr Logo" />
      </a>
    </div>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <SitemapItem
        style={{ margin: "auto", marginTop: "0px", fontSize: "10px" }}
      >
        The Reformed Dissenters © 2022
      </SitemapItem>
    </div>
  </Wrapper>
);
