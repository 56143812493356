import Box from '@mui/material/Box';
import { Navbar, GeneralPageHeader, Sitemap, BackButton } from 'Components';
import { useGetSeries } from 'Hooks';
import { Get_Series_getSeriesList_series } from 'Types';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BackIcon from '@mui/icons-material/ArrowBackIosNew';
import { SeriesGridView } from './SeriesGridView';
import { DarkBackground } from 'colors';
import { Switcher } from './Switcher';
import { PlayerEpisodeBlock } from './PlayerEpisodeBlock';

export type ViewStates = 'player' | 'grid';

export const SeriesPage = () => {
  const [series, setSeries] =
    useState<Get_Series_getSeriesList_series | null>();
  const [viewState, setViewState] = useState<ViewStates>('player');

  const [expandedEpisode, setExpandedEpisode] = useState('');

  const { id } = useParams();
  const navigate = useNavigate();
  const goBack = () => navigate('/series-library');

  const { getSeries, data, getSeriesIsLoading } = useGetSeries();

  // fetch series data from graphql using 'id'
  useEffect(() => {
    getSeries({ variables: { seriesId: id } });
  }, [getSeries, id]);

  useEffect(() => {
    if (data?.getSeriesList?.series) {
      setSeries(data.getSeriesList.series[0]);
      setExpandedEpisode(data.getSeriesList.series[0].seriesEpisodes[0].id);
    }
  }, [data]);

  return (
    <>
      <Navbar />
      <GeneralPageHeader
        mainHeading={series?.seriesTitle || 'Loading'}
        subHeading='The Reformed Dissenters'
      />
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box
          width={{ xs: '75%', md: '500px' }}
          style={{
            padding: '16px 36px 21px 36px',
            boxShadow: '#00000061 1px 1px 11px 1px',
            marginTop: '70px',
            borderRadius: '15px',
            backgroundColor: DarkBackground,
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'space-between',
          }}
        >
          <BackButton style={{ margin: '0px', top: '10px' }} onClick={goBack}>
            <BackIcon style={{ fontSize: '25px' }} />
          </BackButton>
          <Switcher setViewState={setViewState} viewState={viewState} />
        </Box>
      </div>
      <div style={{ minHeight: '500px' }}>
        {series && !getSeriesIsLoading && (
          <>
            {viewState === 'player' ? (
              <Box
                width={{ xs: '100%', md: '70%' }}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: 'auto',
                  marginBottom: '50px',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '-25px',
                    minHeight: '350px',
                    paddingTop: '65px',
                  }}
                >
                  {series.seriesEpisodes.map((episode, idx) => (
                    <PlayerEpisodeBlock
                      index={idx}
                      isLastIndex={idx === series.seriesEpisodes.length - 1}
                      {...episode}
                      expanded={episode.id === expandedEpisode}
                      handleExpandEvent={setExpandedEpisode}
                    />
                  ))}
                </div>
              </Box>
            ) : (
              <SeriesGridView episodes={series.seriesEpisodes} />
            )}
          </>
        )}
      </div>
      <Sitemap />
    </>
  );
};
