import { MustardYellow, StandardGrey } from "../../colors";
import { SectionWrapper, StyledInput } from "Components/FormComponents";
import styled from "styled-components";
import Box from "@mui/material/Box";
import { Link } from "Components";

export const MainWrapper = styled(Box)`
  max-width: 565px;
  margin: auto;
  display: flex;
  gap: 20px;
`;

export const InputLabel = styled.p`
  font-family: "Roboto";
  font-size: 12px;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 5px;
  margin-left: 2px;
`;

export const AdjustedInput = styled(StyledInput)`
  text-align: left;
  width: 180px;
`;

export const CurrentPointsBox = styled.div`
  height: 140px;
  width: 180px;
  border-radius: 12px;
  background-color: ${MustardYellow};
  color: black;
  box-shadow: inset 0px 0px 8px 1px #00000061;
  font-family: "Roboto";
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const ReferralDetailsSection = styled(SectionWrapper)`
  border-radius: 12px;
  padding-top: 14px;
  margin-bottom: 50px;
  text-align: center;
  max-width: 345px;
  display: flex;
  height: 100px;
  margin-top: 0px;
  padding-left: 20px;
  flex-direction: column;
`;

export const ReferralCodeContainer = styled.div`
  background-color: white;
  padding: 5px;
  padding-top: 6px;
  width: 307px;
  border-radius: 8px;
  height: 20px;
  font-family: "Roboto";
  font-size: 14px;
  text-align: left;
  padding-left: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const ReferralLink = styled(Link)`
  color: grey;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const ShareButton = styled.a`
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 1px;
  box-shadow: 1px 1px 3px #80808063;
  background-color: ${StandardGrey};
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 6px;
  width: 28px;
  height: 25px;
`;

export const UnverifiedEmailSection = styled.div`
  font-family: "Roboto";
  max-width: 550px;
  margin: auto;
  margin-bottom: 50px;
  line-height: 22px;
  font-size: 17px;
  padding: 20px;
`;

export const UnverifiedEmailStep = styled.div`
  margin: auto;
  font-size: 20px;
  margin-bottom: 14px;
  margin-top: 14px;
  max-width: 250px;
`;

export const MerchConfirmationMessage = styled.div`
  font-size: 20px;
  margin: auto;
  text-align: center;
  margin-bottom: 30px;
  color: green;
  font-family: "Roboto";
  letter-spacing: 1px;
`;

export const ExplainerText = styled.div`
  font-size: 16px;
  font-family: Roboto;
  text-align: center;
  max-width: 401px;
  margin: auto auto 50px;
  line-height: 24px;
  padding-right: 20px;
  padding-left: 20px;
`;

export const MerchBoxOverlay = styled.div<{
  referralPointsCount: number;
  merchPoints: number;
}>`
  position: absolute;
  height: 140px;
  width: 182px;
  background-color: ${({ referralPointsCount, merchPoints }) =>
    !(referralPointsCount >= merchPoints) ? "#8080807d" : "none"};
  border-radius: 10px;
`;
