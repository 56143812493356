import OldButton from "@mui/material/Button";
import { SkyBlue } from "../colors";
import styled from "styled-components";

interface ButtonProps {
  kind?: "filled";
}

export const Button = styled(OldButton)<ButtonProps>`
  && {
    /* ${({ kind }) => kind === "filled" && `background-color: #dddddd;`} */
    font-family: "Roboto";
    text-transform: none;
    color: white;
    font-size: 14px;
    background-color: ${SkyBlue};
    border-radius: 10px;
    padding-left: 12px;
    padding-right: 12px;
    box-shadow: #0000005e 2.5px 2.5px 6px 0px;
    &:hover {
      background-color: grey;
    }
  }
`;
