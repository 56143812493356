import { Button } from "Components";
import { InputError, StyledInput } from "Components/FormComponents";
import { useClaimFreeMerch, useCurrentUser } from "Hooks";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import { MerchOptions } from "../merchSelectionsData";

const validationSchema = Yup.object({
  name: Yup.string().required("Please enter your name"),
  address: Yup.string().required("Please enter your address"),
});

interface Props {
  merchType: MerchOptions;
  handleClose(args?: any): any;
}

export const ClaimMerchForm: React.FC<Props> = ({ merchType, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState<null | string>(null);
  const { currentUser, refetch } = useCurrentUser();

  const { claimFreeMerch, claimFreeMerchIsLoading, data } = useClaimFreeMerch();

  const {
    handleChange,
    values: { name, address },
    errors,
    handleBlur,
    touched,
    setTouched,
    validateForm,
  } = useFormik({
    initialValues: { name: currentUser?.name || "", address: "" },
    validationSchema,
    onSubmit: () => undefined,
  });

  useEffect(() => {
    const message = data?.claimFreeMerch.message;
    setResponseMessage(message || null);
    if (message && message.includes("Success")) {
      refetch && refetch();
      handleClose({
        message:
          "Your free merch has been claimed! 🎉 Check your inbox for a confirmation email from us.",
      });
    }
    if (message && message.includes("Error")) {
      setResponseMessage(message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const claimMerch = async () => {
    if (currentUser) {
      await claimFreeMerch({
        variables: {
          uid: currentUser.uid,
          name,
          address,
          merchType: merchType.toLowerCase(),
        },
      });
      setLoading(false);
    }
  };

  const handleFormSubmit = async () => {
    setTouched({ name: true, address: true });
    (async () => {
      const formErrors = await validateForm();
      if (!formErrors.name && !formErrors.address) {
        setLoading(true);
        claimMerch();
      }
    })();
  };

  return (
    <>
      {responseMessage && <InputError>{responseMessage}</InputError>}
      <StyledInput
        name="name"
        placeholder="Name"
        disableUnderline
        style={{ width: "90%" }}
        onChange={handleChange}
        value={name}
        onBlur={handleBlur}
      />
      {touched.name && errors.name && <InputError>{errors.name}</InputError>}
      <StyledInput
        name="address"
        placeholder="Address"
        disableUnderline
        style={{ width: "90%", marginTop: "10px" }}
        onChange={handleChange}
        value={address}
        onBlur={handleBlur}
      />
      {touched.address && errors.address && (
        <InputError>{errors.address}</InputError>
      )}
      <Button onClick={handleFormSubmit} style={{ marginTop: "20px" }}>
        {loading || claimFreeMerchIsLoading ? (
          <CircularProgress style={{ color: "white" }} size={20} />
        ) : (
          "Claim your free merch"
        )}
      </Button>
    </>
  );
};
