import { EpisodeType } from "Types";

export const truncateString = (originalString: string, length: number) => {
  if (originalString.length > length) {
    const shortenedString = originalString.split("").slice(0, length).join("");
    return `${shortenedString}...`;
  }

  return originalString;
};

export const getFormattedDate = (originalDate: string) => {
  const newDate = new Date(originalDate);
  const year = newDate.getFullYear();
  const month = newDate.getMonth() + 1;
  // Add 1 to day to account for weird timezone mismatch
  const day = newDate.getDate() + 1;

  const shortMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const entireDate = `${month}/${day}/${year}`;

  const friendlyDate = `${shortMonths[month - 1]} ${day} '${year
    .toString()
    .slice(2, 4)}`;

  return { year, month, day, entireDate, friendlyDate };
};

type EpisodeKeyShape = {
  [key in EpisodeType]: string;
};

export const episodeTypeKey: EpisodeKeyShape = {
  CURRENT_EVENTS: "Current Events",
  LITERATURE_EPISODE: "Literature Episode",
  DISCUSSION_TOPIC: "Discussion Topic",
};
