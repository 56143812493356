import MenuItem from "@mui/material/MenuItem";
import { Button } from "Components";
import React, { useState } from "react";
import { StyledMenuItem, StyledSelect } from "./elements";
import * as Yup from "yup";
import { useFormik } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import {
  SectionWrapper,
  StyledInput,
  FormHeading,
  InputError,
} from "../FormComponents";
import Box from "@mui/material/Box";

const validationSchema = Yup.object({
  name: Yup.string().required("Please enter your name"),
  email: Yup.string()
    .email("Invalid email address")
    .required("You must enter an email address"),
  subject: Yup.string().nullable().required("Please select a subject"),
  message: Yup.string().required("Please enter your message"),
});

export const ContactForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<{
    code: number;
    message: string;
  } | null>(null);

  const dropdownOptions = [
    "Asking a question",
    "Words of encouragement",
    "Sending information/research",
    "Adding a new perspective",
  ];

  const {
    handleChange,
    values: { name, email, subject, message },
    errors,
    handleBlur,
    touched,
    setTouched,
    validateForm,
    resetForm,
  } = useFormik({
    initialValues: { email: "", name: "", subject: null, message: "" },
    validationSchema,
    onSubmit: () => undefined,
  });

  const submitMessage = () => {
    axios
      .post("https://formspree.io/f/xnqwpdnk", {
        name,
        email,
        subject,
        message,
      })
      .then(r => {
        if (r.status === 200)
          setStatus({
            code: 200,
            message:
              "Your message was sent! You should hear back from us within 1-2 business days.",
          });
        resetForm();
        setIsLoading(false);
      })
      .catch(error => {
        if (`${error}`.includes("400"))
          setStatus({
            code: 404,
            message:
              "There was an error! Please ensure all the fields are filled above. If all the fields have been filled in, please wait a minute before trying again.",
          });
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleFormSubmit = () => {
    setTouched({ name: true, email: true, subject: true, message: true });
    (async () => {
      const formErrors = await validateForm();
      if (
        !formErrors.name &&
        !formErrors.email &&
        !formErrors.subject &&
        !formErrors.message
      ) {
        setIsLoading(true);
        submitMessage();
      }
    })();
  };

  return (
    <SectionWrapper
      style={{
        paddingTop: "60px",
        paddingBottom: "60px",
      }}
    >
      <Box maxWidth={{ xs: "350px", md: "424px" }} style={{ margin: "auto" }}>
        <FormHeading>Contact the show</FormHeading>
        <div
          style={{
            margin: "auto",
            flex: 1,
            display: "flex",
          }}
        >
          <StyledInput
            name="name"
            onChange={handleChange}
            value={name}
            placeholder="Name"
            onBlur={handleBlur}
            disableUnderline
            style={{ marginRight: 10, display: "flex", flexGrow: "inherit" }}
          />
          <StyledInput
            onChange={handleChange}
            name="email"
            value={email}
            placeholder="Email"
            onBlur={handleBlur}
            disableUnderline
            style={{ display: "flex", flexGrow: "inherit" }}
          />
        </div>
        {touched.email && errors.email && (
          <InputError>{errors.email}</InputError>
        )}
        {touched.name && errors.name && <InputError>{errors.name}</InputError>}
        <br />
        <div
          style={{
            margin: "auto",
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <StyledSelect
            value={subject}
            onChange={handleChange}
            name="subject"
            onBlur={handleBlur}
            disableUnderline
            variant="standard"
            displayEmpty
            style={{
              width: "100%",
              marginTop: "0px",
              display: "flex",
              flexGrow: "inherit",
            }}
            renderValue={selectedItem => (
              <StyledMenuItem
                style={{ color: !selectedItem ? "#b9b9b9" : "black" }}
              >{`${
                selectedItem || "Why are you getting in contact?"
              }`}</StyledMenuItem>
            )}
          >
            {dropdownOptions.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </StyledSelect>
          {touched.subject && errors.subject && (
            <InputError>{errors.subject}</InputError>
          )}
          <br />
          <StyledInput
            value={message}
            onChange={handleChange}
            onBlur={handleBlur}
            name="message"
            placeholder="Your message"
            disableUnderline
            style={{
              height: "auto",
              padding: 10,
              display: "flex",
              flexGrow: "inherit",
            }}
            multiline
            rows={4}
          />
          {touched.message && errors.message && (
            <InputError>{errors.message}</InputError>
          )}
          {status?.message && (
            <InputError
              style={{ color: status?.code === 404 ? "red" : "green" }}
            >
              {status?.message}
            </InputError>
          )}
          <Button
            disabled={isLoading}
            onClick={handleFormSubmit}
            style={{
              paddingTop: "0px",
              paddingBottom: "0px",
              borderRadius: "6px",
              fontSize: "18px",
              display: "block",
              margin: "auto",
              marginTop: "25px",
              height: "40px",
              width: "90px",
            }}
          >
            {isLoading ? (
              <CircularProgress
                style={{ color: "white", marginTop: 10 }}
                size={20}
              />
            ) : (
              "Send"
            )}
          </Button>
        </div>
      </Box>
    </SectionWrapper>
  );
};
