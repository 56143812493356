import Box from "@mui/material/Box";
import {
  EpisodeTitle,
  EpisodeViewsText,
  SectionHeader,
  SectionWrapper,
  VideoIframe,
} from "./elements";
import BackIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { Episode } from "Types";
import Grid from "@mui/material/Grid";
import { episodeTypeKey, getFormattedDate } from "utilities";
import { SocialShareButtons } from "./SocialShareButtons";
import { EpisodeInfo } from "./EpisodeInfo";
import { DarkBackground } from "colors";
import { TagPill, BackButton } from "Components";
import { DescriptionLinkSection } from "./DescriptionLinksSection";

export const EpisodeContent: React.FC<{ episode: Episode }> = ({ episode }) => {
  const navigate = useNavigate();

  const goBack = () => navigate("/episodes");

  const { entireDate } = getFormattedDate(episode.publishDate || "");

  return (
    <Box
      maxWidth={{ xs: "440px", md: "1000px" }}
      padding={{ xs: "15px", md: "" }}
      paddingTop={{ xs: "30px", md: "40px" }}
      style={{
        textAlign: "center",
        margin: "auto",
        paddingBottom: "60px",
        backgroundColor: DarkBackground,
      }}
    >
      <Grid container>
        <Grid item xs={12} md={8}>
          <BackButton onClick={goBack}>
            <BackIcon style={{ fontSize: "18px" }} />
            Back
          </BackButton>
          <EpisodeTitle>{episode.title}</EpisodeTitle>
          <Box height={{ xs: "260px", md: "375px" }}>
            <VideoIframe src={episode.rumbleEmbedLink} />
          </Box>
          <EpisodeViewsText>Views: {episode.episodeViews}</EpisodeViewsText>

          {/* Description and Links Switcher Section */}
          <DescriptionLinkSection
            description={episode.episodeDescription || ""}
            links={episode.episodeLinks}
          />
        </Grid>
        <Grid
          item
          xs={0}
          md={4}
          paddingLeft={{ xs: "0px", md: "30px" }}
          style={{ paddingTop: "56px", width: "100%", textAlign: "center" }}
        >
          <Box width={{ xs: "80%", md: "inherit" }} style={{ margin: "auto" }}>
            <EpisodeInfo
              episodeNumber={episode.episodeNumber}
              episodeType={episodeTypeKey[episode.episodeType]}
              publishDate={entireDate}
            />

            {/* Share episode section */}
            <SectionWrapper style={{ marginTop: "20px" }}>
              <SectionHeader>Share Episode</SectionHeader>
              <SocialShareButtons />
            </SectionWrapper>

            {/* Tags section */}
            <SectionWrapper style={{ marginTop: "20px" }}>
              <SectionHeader>Tags</SectionHeader>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {episode.tags?.map(({ title }) => (
                  <TagPill>{title}</TagPill>
                ))}
              </div>
            </SectionWrapper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
