import Grid from "@mui/material/Grid";
import { PaleBlue, PaleGreen } from "../../colors";
import {
  GeneralPageHeader,
  ReferralProgramAd,
  Navbar,
  Sitemap,
  TagPill,
} from "Components";
import React from "react";
import {
  Bold,
  HostCard,
  HostSectionWrapper,
  InfoBox,
  InfoBoxHeader,
  MainSynopsis,
  SectionHeader,
} from "./elements";
import Box from "@mui/material/Box";
import { hosts } from "./aboutPageData";
import styled from "styled-components";

const StyledPill = styled(TagPill)`
  background-color: #74bffc;
`;

export const AboutPage: React.FC = () => (
  <>
    <Navbar />
    <GeneralPageHeader
      mainHeading="About"
      subHeading="The Reformed Dissenters"
    />
    <div style={{ backgroundColor: "white" }}>
      <Box
        width={{ xs: "300px", md: "auto" }}
        style={{ margin: "auto" }}
        paddingTop={{ xs: "30px", sm: "40px" }}
        paddingBottom={{ xs: "25px", md: "40px" }}
      >
        <MainSynopsis>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "40px",
              flexWrap: "wrap",
            }}
          >
            <StyledPill>Christ-centered</StyledPill>
            <StyledPill>reformed</StyledPill>
            <StyledPill>optimistic</StyledPill>
            <StyledPill>Kingdom-oriented</StyledPill>
          </div>
          <Bold>Matthew 5:13 - </Bold>
          The Reformed Dissenters is a show that offers a faith-based dissenting
          view on culture, current events, and our modern world. We believe that
          God should be at the center of everything we do and that His
          principles are greater than all the lofty ideals of man.
          <br />
          <br />
          Our mission is to further the Kingdom of Christ throughout all of
          society. As Cornelius Van Til said, "unless we press the crown rights
          of our King in <Bold>every</Bold> realm, we shall not long retain them
          in
          <Bold> any</Bold> realm." This is our vision and goal. Therefore, we
          dissent and stand against anything that goes contrary to the
          infallible Word of God and seek to apply scripture to all areas of
          life!
        </MainSynopsis>
      </Box>
      <Grid
        container
        style={{
          textAlign: "center",
          maxWidth: 630,
          margin: "auto",
          marginBottom: 70,
        }}
      >
        <Grid item xs={12} md={6}>
          <InfoBox style={{ backgroundColor: PaleGreen }}>
            <InfoBoxHeader>Our core focus</InfoBoxHeader>
            <div style={{ marginTop: 7, padding: 10 }}>
              <div style={{ marginBottom: "20px" }}>
                Our core mission is to help Christians apply{" "}
                <div style={{ display: "inline", fontWeight: "bold" }}>
                  all of scripture to all of life
                </div>
                {""}.
              </div>
              We want our show to be informative, thought-provoking, and above
              all; God-centered.
            </div>
          </InfoBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <InfoBox style={{ backgroundColor: PaleBlue }}>
            <InfoBoxHeader>What sets us apart from other shows?</InfoBoxHeader>
            <div
              style={{
                fontSize: 14,
                textAlign: "left",
                paddingTop: 10,
                lineHeight: 1.4,
                letterSpacing: 0.5,
              }}
            >
              As dissenters against popular ideas of government and culture, we
              want to provide you with the resources you need to{" "}
              <Bold>do your own research</Bold>.
              <br />
              <br />
              Ultimately, we want you, the listener, to walk away with{" "}
              <Bold>new information</Bold> and <Bold>new things to study</Bold>{" "}
              both in the word of God and the principled literature we read on
              the show every month.
            </div>
          </InfoBox>
        </Grid>
      </Grid>

      {/* ––– HOSTS CARDS SECTION ––– */}
      <SectionHeader>Hosts</SectionHeader>
      <HostSectionWrapper>
        {hosts.map(({ name, bio, headshotUrl }) => (
          <HostCard>
            <img
              src={headshotUrl}
              style={{
                borderRadius: "50%",
                marginTop: "10px",
                display: "inline-block",
                float: "left",
                marginRight: "16px",
                marginBottom: "6px",
                shapeOutside: "circle()",
                // imageRendering: "-webkit-optimize-contrast",
                objectFit: "cover",
              }}
              height="110px"
              width="110px"
              alt=""
            />
            <div
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "12px",
                display: "inline-block",
              }}
            >
              {name}
            </div>
            <div
              style={{
                fontSize: "15px",
                lineHeight: "19px",
              }}
            >
              {bio}
            </div>
          </HostCard>
        ))}
      </HostSectionWrapper>
      <div style={{ marginBottom: "60px" }}>
        <ReferralProgramAd />
      </div>
    </div>
    <Sitemap />
  </>
);
