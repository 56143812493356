import React from "react";
import { EpisodeType, Episode_Card } from "Types";
import { useNavigate } from "react-router-dom";
import { truncateString, getFormattedDate } from "utilities";
import { MainLogo } from "Icons";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import {
  CardWrapper,
  TopHeaderWrapper,
  LowerSectionWrapper,
  TitleText,
  TagsWrapper,
  MiddleSectionWrapper,
  LowerSectionDetails,
  EpisodeTypeWrapper,
} from "./elements";

export const EpisodeCard: React.FC<Episode_Card> = ({
  title,
  publishDate,
  id,
  tags,
  episodeType,
  episodeNumber,
  episodeThumbnail,
}) => {
  const navigate = useNavigate();

  const { friendlyDate } = getFormattedDate(publishDate);

  const truncatedTitle = truncateString(title, 52);

  type EpisodeKeyShape = {
    [key in EpisodeType]: string;
  };

  const episodeTypeKey: EpisodeKeyShape = {
    CURRENT_EVENTS: "Current Events",
    LITERATURE_EPISODE: "Literature Episode",
    DISCUSSION_TOPIC: "Discussion Topic",
  };

  const episodeTypeIcon = {
    CURRENT_EVENTS: (
      <LiveTvIcon
        style={{
          height: "30px",
          width: "50px",
          top: "-3px",
          position: "relative",
        }}
      />
    ),
    LITERATURE_EPISODE: (
      <MenuBookIcon style={{ height: "30px", width: "50px" }} />
    ),
    DISCUSSION_TOPIC: (
      <QuestionAnswerIcon style={{ height: "30px", width: "50px" }} />
    ),
  };

  const backgroundColor = {
    CURRENT_EVENTS: ["rgb(114 181 167)", "rgb(126 174 166)"],
    LITERATURE_EPISODE: ["rgb(181 174 114)", "rgb(174 166 126)"],
    DISCUSSION_TOPIC: ["rgb(114 154 181)", "rgb(126 153 174)"],
  };

  const firstThreeTags = tags?.slice(0, 3);

  return (
    <div style={{ display: "flex", width: "250px" }}>
      <CardWrapper onClick={() => navigate(`/episode/${id}`)}>
        <div style={{ flexDirection: "column", width: "100%" }}>
          <TopHeaderWrapper>
            <div>#{episodeNumber}</div>
            <div>{friendlyDate}</div>
          </TopHeaderWrapper>
          <MiddleSectionWrapper backgroundColors={backgroundColor[episodeType]}>
            {episodeThumbnail ? (
              <img
                alt=""
                style={{ height: "144px", width: "250px" }}
                src={episodeThumbnail.url}
              />
            ) : (
              <>
                <MainLogo
                  fill="black"
                  style={{
                    height: "114px",
                    width: "auto",
                    position: "relative",
                  }}
                />
                <LowerSectionDetails>
                  <EpisodeTypeWrapper>
                    {episodeTypeKey[episodeType]}
                  </EpisodeTypeWrapper>
                  <div
                    style={{
                      display: "flex",
                      width: "fit-content",
                      position: "relative",
                    }}
                  >
                    {episodeTypeIcon[episodeType]}
                  </div>
                </LowerSectionDetails>
              </>
            )}
          </MiddleSectionWrapper>
        </div>
        <LowerSectionWrapper>
          <TitleText>{truncatedTitle}</TitleText>
          <TagsWrapper>
            {firstThreeTags?.slice(0, 3).map(({ title }, idx) => (
              <>
                {title.toUpperCase()}{" "}
                {`${idx !== firstThreeTags?.length - 1 ? " • " : ""}`}
              </>
            ))}
          </TagsWrapper>
        </LowerSectionWrapper>
      </CardWrapper>
    </div>
  );
};
