import React from "react";
import styled from "styled-components";
import { PicturePageHeader } from "./PicturePageHeader";

const HeadingWrapper = styled.div`
  font-family: "Roboto", sans-serif;
  color: black;
  font-size: 2.6rem;
  padding: 20px;
  display: block;
  margin: auto;
  font-weight: 300;
`;

export const Header: React.FC = () => (
  <>
    <PicturePageHeader />
    <HeadingWrapper>
      <div
        style={{
          fontSize: "1.2rem",
          marginTop: "65px",
          marginBottom: "44px",
          fontWeight: 100,
          letterSpacing: "1.2px",
          textAlign: "center",
        }}
      >
        Episodes uploaded every Tuesday at 7:00AM EST.
      </div>
    </HeadingWrapper>
  </>
);
