import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { StyledInput } from "Components/FormComponents";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import { EpisodesListRefetch } from "Hooks";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { SearchByOptions } from "Types";

interface Props {
  searchText: string;
  setSearchText: React.Dispatch<string>;
  onSearchBarFocus?(): void;
  onSearchBarUnfocus?(): void;
  refetchEpisodesList: EpisodesListRefetch;
  searchByFilter: SearchByOptions;
  setSearchByFilter: React.Dispatch<SearchByOptions>;
  noResultsFound?: boolean;
}

export const SearchBarAndDropDownMenu: React.FC<Props> = ({
  searchText,
  setSearchText,
  onSearchBarFocus,
  onSearchBarUnfocus,
  refetchEpisodesList,
  searchByFilter,
  setSearchByFilter,
  noResultsFound = false,
}) => (
  <>
    <Box
      width={{ xs: "340px", sm: "400px", md: "500px" }}
      margin={{ xs: "auto 10px auto 10px", md: "40px auto" }}
      style={{
        marginTop: "35px",
        marginBottom: "85px",
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}
      >
        <Button
          onClick={() =>
            refetchEpisodesList({
              textFilter: searchText,
              searchBy: searchByFilter,
            })
          }
          style={{ padding: "0px", marginRight: "5px" }}
          color="inherit"
        >
          <SearchIcon
            style={{ color: "white", height: "30px", width: "50px" }}
          />
        </Button>
        <StyledInput
          onFocus={onSearchBarFocus}
          onBlur={onSearchBarUnfocus}
          disableUnderline
          placeholder={`Search by episode ${
            searchByFilter !== "tags" ? "title and description" : "tags"
          }`}
          style={{ border: "solid grey 1px", width: "100%" }}
          onChange={e => setSearchText(e.target.value)}
          value={searchText}
        />
      </div>
      {noResultsFound && (
        <div
          style={{
            marginTop: "30px",
            fontFamily: "Roboto",
            lineHeight: "30px",
            color: "white",
          }}
        >
          Not finding what you're looking for?
          <br />
          Try filtering by{" "}
          {searchByFilter === "tags" ? "title / description" : "tags"}:
        </div>
      )}
      <RadioGroup
        row
        style={{ marginTop: "10px", justifyContent: "center" }}
        value={searchByFilter}
        onChange={e => setSearchByFilter(e.target.value as SearchByOptions)}
      >
        <FormControlLabel
          style={{ color: "white" }}
          value={SearchByOptions.titleDescription}
          control={<Radio style={{ color: "white" }} />}
          label="Title / Description"
        />
        <FormControlLabel
          style={{ color: "white" }}
          value={SearchByOptions.tags}
          control={<Radio style={{ color: "white" }} />}
          label="Tags"
        />
      </RadioGroup>
    </Box>
  </>
);
