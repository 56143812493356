import styled from "styled-components";

export const MainAdWrapper = styled.div`
  background: transparent linear-gradient(161deg, #51b47c 0%, #31744e 100%) 0%
    0% no-repeat padding-box;
  background: transparent linear-gradient(161deg, #51b47c 0%, #31744e 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 10px 25px #2f455726;
  opacity: 1;
  border-radius: 10px;
  margin: auto;
  padding: 20px;
`;

export const AdHeader = styled.h1`
  font: normal normal bold 30px "Roboto";
  margin-top: 0px;
  max-width: 220px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  color: white;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
`;

export const AdSubHeader = styled(AdHeader)`
  font-size: 17px;
  font-weight: normal;
  max-width: 200px;
  margin-top: 0px;
`;
