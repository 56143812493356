import Box from "@mui/material/Box";
import React from "react";
import { Episode } from "Types";
import { EpisodeCard } from "Components";

interface Props {
  episodes: Episode[];
}

export const EpisodesGrid: React.FC<Props> = ({ episodes }) => (
  <>
    <Box
      maxWidth={{ xs: "300px", sm: "500px", md: "800px" }}
      style={{ margin: "auto", marginTop: "60px" }}
    >
      <div
        style={{
          marginBottom: "40px",
          fontFamily: "Roboto",
          fontSize: "16px",
          textAlign: "left",
          display: "block",
          color: "white",
        }}
      >
        Episodes: {episodes.length}
      </div>
      <Box
        style={{
          display: "flex",
          gap: "20px",
          flexFlow: "wrap",
          justifyContent: "center",
          marginBottom: "40px",
        }}
      >
        {episodes.map(({ ...episode }) => (
          <EpisodeCard key={episode.id} {...episode} />
        ))}
      </Box>
    </Box>
  </>
);
