import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { MainLogo } from "Icons";
import React from "react";
import styled from "styled-components";
import { DarkBackground } from "colors";

const HeaderWrapper = styled(Box)`
  font-family: "Roboto", sans-serif;
  font-size: 2.6rem;
`;

const TitleText = styled.h1`
  font-weight: 600;
  letter-spacing: 2px;
  margin-top: 20px;
  max-width: 272px;
  text-transform: uppercase;
  font-size: 32px;
`;

const InfoText = styled.h2`
  font-family: "Roboto";
  font-size: 15.5px;
  font-weight: 200;
  max-width: 360px;
  letter-spacing: 1.1px;
  margin-bottom: 22px;
  margin-top: 22px;
  line-height: 21px;
`;

const BlinkingLine = styled.div`
  background-color: white;
  height: 2px;
  width: 30px;

  animation: fadein 1s infinite alternate;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const PicturePageHeader: React.FC = () => (
  <HeaderWrapper marginBottom={{ xs: "20px", md: "55px" }}>
    <Box
      height={{ xs: "650px", md: "464px", backgroundColor: DarkBackground }}
      width={"100%"}
      style={{ position: "absolute", zIndex: -2, top: "56px" }}
    />
    <div style={{ marginTop: "74px" }}>
      <Box
        style={{ margin: "auto" }}
        height={{ xs: "200px" }}
        width={{ xs: "200px" }}
        display={{ xs: "inherit", md: "none" }}
      >
        <MainLogo
          fill="white"
          style={{
            height: "auto",
            width: "auto",
            display: "block",
          }}
        />
      </Box>
      <Grid container style={{ maxWidth: "800px", margin: "auto" }}>
        <Grid
          item
          xs={12}
          md={8}
          style={{
            display: "flex",
            verticalAlign: "center",
            justifyContent: "center",
            flexDirection: "column",
            color: "white",
            textAlign: "center",
          }}
        >
          <Box
            maxWidth={{ xs: "270px", md: "fit-content" }}
            margin={{ xs: "auto", md: "0px" }}
            style={{ textAlign: "left" }}
          >
            <TitleText>The Reformed Dissenters</TitleText>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <BlinkingLine />
            </div>
            <InfoText>
              Matthew 5:13 - The show where <b>reformed Christians</b> dissent
              against popular ideas of culture by asserting a{" "}
              <b>biblical worldview</b>.
            </InfoText>
            <InfoText>
              Episodes uploaded{" "}
              <div style={{ display: "inline" }}>
                every <b>Monday</b>, <b>Wednesday</b>, and <b>Friday</b> at{" "}
                <b>7:00AM EST</b>.
              </div>
            </InfoText>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            style={{ margin: "auto 0px auto auto" }}
            height={{ md: "290px" }}
            width={{ md: "290px" }}
            display={{ xs: "none", md: "inherit" }}
          >
            <MainLogo
              fill="white"
              style={{
                height: "auto",
                width: "auto",
                display: "block",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  </HeaderWrapper>
);
