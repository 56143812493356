import { Query_Get_User_Data } from "operations";
import { useLazyQuery } from "@apollo/client";
import { Get_User_Data, Get_User_DataVariables } from "Types";

export const useGetUserData = () => {
  const [getUserData, { data, loading: getUserDataIsLoading, refetch }] =
    useLazyQuery<Get_User_Data, Get_User_DataVariables>(Query_Get_User_Data, {
      fetchPolicy: "network-only",
    });

  return { getUserData, getUserDataIsLoading, data, refetch };
};
