import React from "react";
import Fade from "@mui/material/Fade";
import { ModalBackground, ModalWrapper } from "Components";
import { ClaimMerchForm } from "./ClaimMerchForm";
import { MerchOptions } from "../merchSelectionsData";
import { merchSelectionsData } from "../merchSelectionsData";
import { FormHeading } from "Components/FormComponents";
import Fab from "@mui/material/Fab";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";

interface Props {
  showModal: boolean;
  handleClose(args?: any): any;
  merchType: MerchOptions;
}

const CloseButton = styled(Fab)`
  && {
    height: 36px;
    width: 36px;
    position: relative;
    top: -15px;
    left: -155px;
    background-color: white;
  }
`;

const MerchImageWrapper = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  margin: auto;
  width: fit-content;
  height: fit-content;
  box-shadow: 0px 0px 10px 0px #0000004f;
  margin-bottom: 15px;
  margin-top: -10px;
`;

export const ClaimMerchModal: React.FC<Props> = ({
  showModal,
  handleClose,
  merchType,
}) => {
  const merchImage = merchSelectionsData.find(
    ({ merchTitle }) => merchTitle === merchType
  )?.merchImage;

  const imageSizeIncreaser = merchType === "Tee-shirt" ? 150 : 70;

  if (!merchImage) return null;

  return (
    <Fade in={showModal}>
      <ModalBackground onClick={handleClose}>
        <ModalWrapper
          style={{ width: "320px", maxHeight: "480px" }}
          onClick={e => e.stopPropagation()}
        >
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
          <MerchImageWrapper>
            <img
              alt=""
              height={parseInt(merchImage.height, 10) + imageSizeIncreaser}
              src={merchImage?.src}
            />
          </MerchImageWrapper>
          <FormHeading>Claim your free {merchType.toLowerCase()}</FormHeading>
          <ClaimMerchForm merchType={merchType} handleClose={handleClose} />
        </ModalWrapper>
      </ModalBackground>
    </Fade>
  );
};
