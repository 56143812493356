import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { HandleRegArgs, RegisterFields } from "./RegisterFields";
import { HandleLoginArgs, LoginFields } from "./LoginFields";
import {
  FormStatus,
  useGetCurrentUser,
  useRegisterErrorHandling,
  useRegisterNewUser,
  useLoginErrorHandling,
  useCurrentUser,
} from "Hooks";
import { InputError } from "Components/FormComponents";
import { User } from "Types";
import { useSearchParams } from "react-router-dom";
import { BlueText } from "./elements";
import { ForgotPasswordFields } from "./ForgotPasswordFields";

const Header = styled.div`
  font-family: "Roboto";
  font-weight: 300;
  font-size: 26px;
  margin-bottom: 20px;
`;

type FormModes = "register" | "login" | "forgot-password";

export const LoginRegisterForm: React.FC<{
  onComplete(): void;
  showLoginToggle?: boolean;
  formHeader?: string;
  longFieldsWidth?: boolean;
}> = ({
  onComplete,
  showLoginToggle = true,
  formHeader,
  longFieldsWidth = false,
}) => {
  const [formMode, setFormMode] = useState<FormModes>("register");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<FormStatus>(null);

  const [searchParams] = useSearchParams();

  const referralCodeFromUrl = searchParams.get("code");

  let loginRegisterText = "";
  if (formMode === "register") loginRegisterText = "Register";
  if (formMode === "login") loginRegisterText = "Login";
  if (formMode === "forgot-password") loginRegisterText = "Forgot Password";

  const switchText: "Register" | "Login" =
    formMode !== "register" ? "Register" : "Login";

  const loginAction = () => {
    setFormMode("login");
    setStatus(null);
  };

  useEffect(() => {
    setStatus(null);
  }, [formMode]);

  const {
    registerNewUser,
    registerNewUserIsLoading,
    data: registeredUser,
  } = useRegisterNewUser();

  useRegisterErrorHandling({
    registerNewUserIsLoading,
    registeredUser,
    setStatus,
    loginAction,
    showLoginToggle,
  });

  useEffect(() => {
    if (!registerNewUserIsLoading) setLoading(false);
  }, [registerNewUserIsLoading]);

  const handleRegistration = async ({
    name,
    email,
    password,
  }: HandleRegArgs) => {
    const userData = await registerNewUser({
      variables: {
        name,
        email,
        password,
        awardPointToReferrer: { referralCode: referralCodeFromUrl },
      },
    });

    if (userData && !userData.data?.registerNewUser.hasOwnProperty("message")) {
      if (handleLocalLoginState) {
        handleLocalLoginState({ ...userData.data?.registerNewUser } as User);
        onComplete();
      }
    }
  };

  const {
    getCurrentUser,
    getCurrentUserIsLoading,
    data: currentUser,
  } = useGetCurrentUser();

  const registerAction = () => {
    setFormMode("register");
    setStatus(null);
  };

  useLoginErrorHandling({
    getCurrentUserIsLoading,
    currentUser,
    setStatus,
    registerAction,
  });

  useEffect(() => {
    if (getCurrentUserIsLoading) setLoading(true);
  }, [getCurrentUserIsLoading]);

  const { handleLocalLoginState } = useCurrentUser();

  useEffect(() => {
    if (currentUser && !currentUser.getCurrentUser.hasOwnProperty("message")) {
      if (handleLocalLoginState) {
        handleLocalLoginState({ ...currentUser.getCurrentUser } as User);
        onComplete();
      }
    }

    // if (!getCurrentUserIsLoading) setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, getCurrentUserIsLoading, handleLocalLoginState]);

  const handleLogin = async ({ password, email }: HandleLoginArgs) => {
    setStatus(null);
    getCurrentUser({ variables: { password, email } });
  };

  return (
    <>
      <Header>{formHeader || loginRegisterText}</Header>
      {formMode === "register" ? (
        <RegisterFields
          longFieldsWidth={longFieldsWidth}
          loading={loading}
          setLoading={setLoading}
          handleRegistration={handleRegistration}
        />
      ) : formMode === "forgot-password" ? (
        <ForgotPasswordFields handleCancelClick={() => setFormMode("login")} />
      ) : (
        <LoginFields
          loading={loading}
          setLoading={setLoading}
          handleLogin={handleLogin}
          handleForgotPasswordClick={() => setFormMode("forgot-password")}
        />
      )}
      {status?.message && (
        <InputError style={{ color: status?.code === 404 ? "red" : "green" }}>
          {status?.message}
        </InputError>
      )}
      {showLoginToggle && formMode !== "forgot-password" && (
        <BlueText
          onClick={() => setFormMode(switchText.toLowerCase() as FormModes)}
        >
          {switchText}
        </BlueText>
      )}
    </>
  );
};
