import Button from "@mui/material/Button";
import styled from "styled-components";

export const SectionHeader = styled.div`
  font-size: 24px;
  font-family: "Roboto";
  letter-spacing: 0.7px;
  font-weight: 100;
`;

export const BackgroundGradient = styled.div<{ backgroundColors: string[] }>`
  background-color: ${({ backgroundColors }) => backgroundColors[0]};
  background-image: radial-gradient(
      at 47% 33%,
      ${({ backgroundColors }) => backgroundColors[1]} 0px,
      #0000002b 59%
    ),
    radial-gradient(at 82% 65%, rgb(17 25 39 / 41%) 0px, #00000012 55%);
  height: 100%;
  width: 100%;
  box-shadow: inset #00000078 0px 0px 20px 2px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0px 10px;
  &:hover {
    cursor: pointer;
  }
`;

export const EpisodeTitle = styled.h3`
  font-family: "Roboto";
  font-size: inherit;
  text-transform: uppercase;
  color: white;
  text-shadow: 0px 0px 4px #434343;
`;

export const EpisodeDate = styled.p`
  font-size: 15px;
  color: white;
  font-family: Roboto;
  margin: 4px;
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-family: "Roboto";
  margin-top: 12px;
  font-size: 14px;
  letter-spacing: 1px;
  flex-wrap: wrap;
  line-height: 18px;
  align-items: center;
  height: inherit;
  color: white;
  justify-content: center;
`;

export const ViewNowButton = styled(Button)`
  && {
    color: white;
    background-color: #525252;
    border-radius: 18px;
    padding: 6px 23px;
    font-size: 12px;
    margin-top: 16px;
    margin-bottom: 12px;
  }
`;
