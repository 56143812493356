import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { BlueText, FormField } from "./elements";
import * as Yup from "yup";
import { InputError } from "Components/FormComponents";
import { Button } from "Components";
import CircularProgress from "@mui/material/CircularProgress";
import { useSendPasswordResetEmail } from "Hooks";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("You must enter an email address"),
});

interface Props {
  handleCancelClick(): any;
}

export const ForgotPasswordFields: React.FC<Props> = ({
  handleCancelClick,
}) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<null | string>(null);

  const { sendPasswordResetEmail, sendPasswordResetEmailIsLoading, data } =
    useSendPasswordResetEmail();

  useEffect(() => {
    if (sendPasswordResetEmailIsLoading) setLoading(true);
    else if (!sendPasswordResetEmailIsLoading && data && loading) {
      setLoading(false);
      setMessage(data.sendPasswordResetEmail.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendPasswordResetEmailIsLoading]);

  const {
    handleChange,
    values: { email },
    errors,
    handleBlur,
    touched,
    setTouched,
    validateForm,
  } = useFormik({
    initialValues: { email: "" },
    validationSchema,
    onSubmit: () => undefined,
  });

  const handleFormSubmit = () => {
    setTouched({
      email: true,
    });
    (async () => {
      const formErrors = await validateForm();
      if (!formErrors.email) {
        setLoading(true);
        sendPasswordResetEmail({ variables: { email } });
      }
    })();
  };

  return (
    <>
      <FormField
        style={{ marginTop: "25px" }}
        name="email"
        placeholder="Email"
        disableUnderline
        onChange={handleChange}
        value={email}
        onBlur={handleBlur}
      />
      {touched.email && errors.email && <InputError>{errors.email}</InputError>}
      {message && (
        <div
          style={{
            color: message.includes("Error") ? "red" : "#009f00",
            fontFamily: "Roboto",
            width: "240px",
            margin: "auto",
            marginTop: "20px",
            lineHeight: "24px",
          }}
        >
          {message}
        </div>
      )}
      <Button
        disabled={loading}
        style={{ marginTop: "20px" }}
        onClick={handleFormSubmit}
      >
        {loading ? (
          <CircularProgress style={{ color: "white" }} size={20} />
        ) : (
          "Send Password Reset Email"
        )}
      </Button>
      <BlueText onClick={handleCancelClick}>Back to Login</BlueText>
    </>
  );
};
