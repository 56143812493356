import { Button } from "Components";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Register_New_User } from "Types";

export type FormStatus = {
  code: number;
  message: string | React.ReactElement;
} | null;

interface Inputs {
  registerNewUserIsLoading: boolean;
  registeredUser: Register_New_User | null | undefined;
  setStatus: React.Dispatch<FormStatus>;
  loginAction?: () => any;
  showLoginToggle?: boolean;
}

export const useRegisterErrorHandling = ({
  registerNewUserIsLoading,
  registeredUser,
  setStatus,
  loginAction,
  showLoginToggle = true,
}: Inputs) => {
  const navigate = useNavigate();

  const navToLogin = () => navigate("/login");

  useEffect(() => {
    if (!registerNewUserIsLoading && registeredUser) {
      // Handle error messages
      if ("message" in registeredUser.registerNewUser) {
        // Handle case where user already exists
        if (
          registeredUser.registerNewUser.message?.includes(
            "auth/email-already-in-use"
          )
        ) {
          setStatus({
            code: 404,
            message: showLoginToggle ? (
              <>
                {"This email is already registered to an account. Please "}
                <Button
                  style={{ height: "30px" }}
                  onClick={loginAction || navToLogin}
                >
                  Login
                </Button>
              </>
            ) : (
              <>
                This email is already registered to an account. Please try again
                with a different email address.
              </>
            ),
          });
          return;
        }

        // General display for all other errors
        setStatus({
          code: 404,
          message: registeredUser.registerNewUser.message || "",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerNewUserIsLoading, registeredUser]);
};
