import React from "react";
import {
  AdBodyText,
  AdHeader,
  GreenAdBox,
  GreySection,
  GreySectionSubheading,
} from "./elements";
import Box from "@mui/material/Box";
import { FormHeading } from "Components/FormComponents";
import { Button } from "Components";
import { useNavigate } from "react-router-dom";

export const ReferralProgramAd: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        onClick={() => navigate("/refer")}
        maxWidth={{ xs: "360px", md: "750px" }}
        style={{ margin: "auto", marginTop: "75px", marginBottom: "40px" }}
      >
        <GreySection>
          <FormHeading style={{ margin: "0px", textAlign: "left" }}>
            TRD Referral Program
          </FormHeading>
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              gap: "15px",
              marginTop: "12px",
            }}
          >
            <GreySectionSubheading>
              Head over to the Referral Program page to get started.
            </GreySectionSubheading>
            <Button
              style={{ height: "50px", width: "136px", lineHeight: "18px" }}
              onClick={() => navigate("/refer")}
            >
              Referral Program
            </Button>
          </div>
        </GreySection>
        <GreenAdBox>
          <Box textAlign={{ xs: "center", md: "left" }}>
            <AdHeader>Share TRD with your friends, get FREE merch!</AdHeader>
            <Box height={{ xs: "340px", md: "40px" }}>
              <AdBodyText>
                Earn points every time you share TRD with one of your friends.
                The more points the better the merchandise we will send you.
              </AdBodyText>
              <Box
                height={{ xs: "280px", md: "180px" }}
                position={{ xs: "unset", md: "relative" }}
                right={{ xs: "0px", md: "-560px" }}
                top={{ xs: "0px", md: "-160px" }}
                style={{ maxWidth: "300px" }}
                margin={{ xs: "auto", md: "0px" }}
              >
                <img
                  src="https://trd-show.s3.amazonaws.com/merch-images/trd-teeshirt.png"
                  height="100%"
                  alt=""
                />
              </Box>
            </Box>
          </Box>
        </GreenAdBox>
      </Box>
    </>
  );
};
