import Tabs from '@mui/material/Tabs';
import { ViewStates } from './SeriesPage';
import Tab from '@mui/material/Tab';

interface Props {
  viewState: ViewStates;
  setViewState: React.Dispatch<React.SetStateAction<ViewStates>>;
}

export const Switcher = ({ setViewState, viewState }: Props) => {
  const handleChange = (event: React.SyntheticEvent, newValue: ViewStates) => {
    setViewState(newValue);
  };

  const getTabStyles = (selected: boolean) => {
    const commonStyles = { fontFamily: 'Roboto', letterSpacing: '1.5px' };
    if (selected) return { color: 'white', ...commonStyles };
    if (!selected) return { color: 'grey', ...commonStyles };
  };

  return (
    <>
      <Tabs value={viewState} onChange={handleChange}>
        <Tab
          value='grid'
          style={getTabStyles(viewState === 'grid')}
          label='Grid'
        />
        <Tab
          value='player'
          style={getTabStyles(viewState === 'player')}
          label='Player'
        />
      </Tabs>
    </>
  );
};
