import { PaleGrey } from "../../colors";
import styled from "styled-components";
import Box from "@mui/material/Box";

export const FormWrapper = styled(Box)`
  max-width: 570px;
  max-height: 426px;
  overflow: scroll;
  min-height: 200px;
  border-radius: 10px;
  padding: 20px;
  margin: auto;
  margin-bottom: 34px;
  text-align: center;
  background-color: ${PaleGrey};
`;
