interface Host {
  name: string;
  bio: string;
  headshotUrl: string;
}

export const hosts: Host[] = [
  {
    name: "Bruce Johnson",
    bio: "Bruce is a software engineer by trade and has worked with code since he was 13 years old. He loves playing music and currently plays violin, guitar, piano, cello, upright bass, and mandolin. Bruce also teaches music at a music school in his area. Additionally, he enjoys spending time outside biking and running and was in Boy Scouts for eight years. He also earned his black belt in Tang Soo Do in 2017. Bruce thoroughly enjoys teaching and always tries to show the love of Christ through kindness and patience with his students.",
    headshotUrl: "https://trd-show.s3.amazonaws.com/bruce-headshot.jpeg",
  },
  {
    name: "Jacob Johnson",
    bio: "Jacob lives in Pennsylvania and has lived there all of his life. His goal is to become a chef and study at a culinary school. He plays both clarinet and percussion and, until recently, played in a homeschool band. Jacob has earned a red belt in Tang Soo Do, which is one belt below black. Jacob cooks for his family every night and currently works in a local restaurant as a cook.",
    headshotUrl: "https://trd-show.s3.amazonaws.com/jacob-headshot.jpeg",
  },
];
