import React from "react";
import odyseeLogo from "Images/odysee-logo.png";
import gabLogo from "Images/gab-logo.png";
import gabtvLogo from "Images/gabtv-logo.png";
import rumbleLogo from "Images/rumble-logo.png";
import youTubeLogo from "Images/youtube-logo.png";
import spotifyLogo from "Images/spotify-logo.png";
import iHeartRadioLogo from "Images/iheartradio-logo.png";
import applePodcastsLogo from "Images/apple-podcasts-logo.png";
import gettrLogo from "Images/gettr-logo.png";
import facebookLogo from "Images/facebook-logo.png";
import instagramLogo from "Images/instagram-logo.png";
import styled from "styled-components";
import Box from "@mui/material/Box";

interface Platform {
  name: string;
  link: string;
  image: { url: string; height?: string; width?: string };
}

const platformLinks: Platform[] = [
  {
    name: "",
    link: "https://gab.com/trdshow",
    image: { url: gabLogo, width: "85px", height: "47px" },
  },
  {
    name: "",
    link: "https://tv.gab.com/channel/trdshow",
    image: { url: gabtvLogo, width: "116px", height: "70px" },
  },
  {
    name: "",
    link: "https://www.gettr.com/user/trdshow",
    image: { url: gettrLogo, width: "110px", height: "46px" },
  },
  {
    name: "Odysee",
    link: "https://odysee.com/@trdshow",
    image: { url: odyseeLogo },
  },
  {
    name: "Rumble",
    link: "https://rumble.com/c/c-951217",
    image: { url: rumbleLogo },
  },
  {
    name: "Apple Podcasts",
    link: "https://podcasts.apple.com/us/podcast/the-reformed-dissenters/id1578489991",
    image: { url: applePodcastsLogo, height: "42px", width: "42px" },
  },
  {
    name: "",
    link: "https://www.youtube.com/channel/UCCSLo5h6N9n8N5LqN0RdQ4g",
    image: { url: youTubeLogo, height: "34", width: "132" },
  },
  {
    name: "",
    link: "https://open.spotify.com/show/4DonJKILKYvWnvtRlMfics",
    image: { url: spotifyLogo, width: "125", height: "35" },
  },
  {
    name: "",
    link: "https://www.iheart.com/podcast/85233930",
    image: { url: iHeartRadioLogo, width: "120", height: "25" },
  },
  {
    name: "",
    link: "https://www.facebook.com/thereformeddissenters",
    image: { url: facebookLogo, width: "130", height: "30" },
  },
  {
    name: "",
    link: "https://www.instagram.com/thereformeddissenters/",
    image: { url: instagramLogo, width: "90", height: "85" },
  },
];

const PlatformBox = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  border-radius: 10px;
  width: 120px;
  padding: 14px;
  margin: 6px;
  height: 90px;
  background-color: #f5f5f5d9;
  text-align: center;
  justify-content: center;
`;

export const PlatformLinks: React.FC = () => (
  <Box
    justifyContent={{ xs: "center", md: "flex-start" }}
    style={{
      display: "flex",
      alignContent: "space-around",
      flexFlow: "wrap",
      fontFamily: "Roboto",
      maxWidth: "700px",
    }}
  >
    {platformLinks.map(
      ({ name, link, image: { url, height = "32px", width = "32px" } }) => (
        <PlatformBox key={name} href={link} target="_blank" rel="noreferrer">
          <img
            style={{ display: "inline-block" }}
            src={url}
            alt={name}
            height={height}
            width={width}
          />
          {name && (
            <div
              style={{
                display: "inline-block",
                marginLeft: "10px",
                fontSize: "1.2rem",
                color: "black",
              }}
            >
              {name}
            </div>
          )}
        </PlatformBox>
      )
    )}
  </Box>
);
