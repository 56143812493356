import Box from "@mui/material/Box";
import { Button } from "Components";
import React, { useState } from "react";
import { AdjustedInput, InputLabel } from "../elements";
import * as Yup from "yup";
import { useCurrentUser, useUpdateUserSettings } from "Hooks";
import { useFormik } from "formik";
import { InputError } from "Components/FormComponents";
import CircularProgress from "@mui/material/CircularProgress";

const validationSchema = Yup.object({
  name: Yup.string().required("Please enter your name"),
  email: Yup.string()
    .email("Invalid email address")
    .required("You must enter an email address"),
});

export const UpdateInfoFields: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { currentUser, refetch } = useCurrentUser();

  const { updateUserSettings } = useUpdateUserSettings();

  const {
    handleChange,
    values: { name, email },
    errors,
    handleBlur,
    touched,
    setTouched,
    validateForm,
  } = useFormik({
    initialValues: {
      email: currentUser?.email || "",
      name: currentUser?.name || "",
    },
    validationSchema,
    onSubmit: () => undefined,
  });

  if (!currentUser) return null;

  const handleFormSubmit = () => {
    setTouched({
      name: true,
      email: true,
    });
    (async () => {
      const formErrors = await validateForm();
      if (!formErrors.name && !formErrors.email) {
        setLoading(true);
        await updateUserSettings({
          variables: { name, email, uid: currentUser.uid },
        });
        setLoading(false);
        refetch && refetch();
      }
    })();
  };

  return (
    <>
      <Box
        justifyContent={{ xs: "center", md: "unset" }}
        style={{
          display: "flex",
          gap: "20px",
          alignContent: "center",
          padding: "15px",
          flexWrap: "wrap",
          flex: 1,
        }}
      >
        <div style={{ flexGrow: "inherit" }}>
          <InputLabel>Name</InputLabel>
          <AdjustedInput
            name="name"
            placeholder="Name"
            disableUnderline
            value={name}
            onChange={handleChange}
            onBlur={handleBlur}
            style={{ width: "100%" }}
          />
          {touched.email && errors.email && (
            <InputError>{errors.email}</InputError>
          )}
        </div>
        <div style={{ flexGrow: "inherit" }}>
          <InputLabel>Email Address</InputLabel>
          <AdjustedInput
            name="email"
            placeholder="Email Address"
            disableUnderline
            value={email}
            onChange={handleChange}
            onBlur={handleBlur}
            style={{ width: "100%" }}
          />
        </div>
        <Box marginTop={{ xs: "0px", md: "18px" }}>
          <Button
            onClick={handleFormSubmit}
            style={{ height: "34px", width: "139px" }}
          >
            {loading ? (
              <CircularProgress style={{ color: "white" }} size={20} />
            ) : (
              "Update Info"
            )}
          </Button>
        </Box>
      </Box>
    </>
  );
};
