import React from "react";
import { Button } from "Components";
import { BlueText, FormField } from "./elements";
import { useFormik } from "formik";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { InputError } from "Components/FormComponents";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("You must enter an email address"),
  password: Yup.string().required("Please enter your password"),
});

export interface HandleLoginArgs {
  email: string;
  password: string;
}

interface Props {
  handleLogin(args: HandleLoginArgs): any;
  loading: boolean;
  setLoading: React.Dispatch<boolean>;
  handleForgotPasswordClick(): any;
}

export const LoginFields: React.FC<Props> = ({
  handleLogin,
  setLoading,
  loading,
  handleForgotPasswordClick,
}) => {
  const {
    handleChange,
    values: { email, password },
    errors,
    handleBlur,
    touched,
    setTouched,
    validateForm,
  } = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema,
    onSubmit: () => undefined,
  });

  const handleFormSubmit = () => {
    setTouched({
      email: true,
      password: true,
    });
    (async () => {
      const formErrors = await validateForm();
      if (!formErrors.email && !formErrors.password) {
        setLoading(true);
        handleLogin({ password, email });
      }
    })();
  };

  return (
    <>
      <FormField
        name="email"
        placeholder="Email"
        disableUnderline
        onChange={handleChange}
        value={email}
        onBlur={handleBlur}
      />
      <br />
      {touched.email && errors.email && <InputError>{errors.email}</InputError>}
      <FormField
        name="password"
        placeholder="Password"
        type="password"
        disableUnderline
        onChange={handleChange}
        value={password}
        onBlur={handleBlur}
      />
      <br />
      {touched.password && errors.password && (
        <InputError>{errors.password}</InputError>
      )}
      <BlueText
        onClick={handleForgotPasswordClick}
        style={{
          fontSize: "12px",
          textAlign: "left",
          width: "275px",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        Forgot Password
      </BlueText>
      <Button style={{ marginTop: "20px" }} onClick={handleFormSubmit}>
        {loading ? (
          <CircularProgress style={{ color: "white" }} size={20} />
        ) : (
          "Login"
        )}
      </Button>
    </>
  );
};
