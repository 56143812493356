import Grid from "@mui/material/Grid";
import React from "react";
import { AdHeader, AdSubHeader, MainAdWrapper } from "./elements";
import MerchStoreGraphic from "Images/merch-store-ad-graphic-transparent.png";
import Box from "@mui/material/Box";
import { Button } from "Components";

export const MerchStoreAd: React.FC = () => (
  <Box
    maxWidth={{ xs: "360px", md: "750px" }}
    style={{ margin: "auto", marginTop: "75px", marginBottom: "75px" }}
  >
    <a
      target="_blank"
      rel="noreferrer"
      href="https://thereformeddissenters.myshopify.com/"
      style={{ textDecoration: "none" }}
    >
      <MainAdWrapper>
        <Grid container>
          <Grid item xs={12} md={7}>
            <Box
              marginBottom={{ xs: "20px", md: "0px" }}
              height={{ xs: "190px", md: "220px" }}
              width={{ xs: "320px", md: "400px" }}
              style={{
                backgroundColor: "white",
                borderRadius: "6px",
                padding: "0px",
              }}
            >
              <img src={MerchStoreGraphic} alt="" height="100%" width="100%" />
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "center",
              }}
            >
              <AdHeader>Help Support The Show</AdHeader>
              <AdSubHeader>
                And Get Reformed Dissenters Merch At The Same Time!
              </AdSubHeader>
              <Box marginBottom={{ xs: "10px", md: "0px" }}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://thereformeddissenters.myshopify.com/"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    style={{
                      maxWidth: "210px",
                      marginRight: "auto",
                      marginLeft: "auto",
                      marginTop: "15px",
                    }}
                  >
                    Visit the TRD Merch Store
                  </Button>
                </a>
              </Box>
            </div>
          </Grid>
        </Grid>
      </MainAdWrapper>
    </a>
  </Box>
);
