import { Mutation_Send_Password_Reset_Email } from "operations";
import { useMutation } from "@apollo/client";
import {
  Send_Password_Reset_Email,
  Send_Password_Reset_EmailVariables,
} from "Types";

export const useSendPasswordResetEmail = () => {
  const [
    sendPasswordResetEmail,
    { data, loading: sendPasswordResetEmailIsLoading },
  ] = useMutation<
    Send_Password_Reset_Email,
    Send_Password_Reset_EmailVariables
  >(Mutation_Send_Password_Reset_Email, { fetchPolicy: "network-only" });

  return { sendPasswordResetEmail, sendPasswordResetEmailIsLoading, data };
};
