import React, { useState } from "react";
import { MainLogo } from "Icons";
import { Link } from "Components";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { navLinks } from "./navLinksData";
import styled from "styled-components";
import Fade from "@mui/material/Fade";
import gabLogo from "Images/gab-logo.png";
import gettrLogoWhite from "Images/gettr-logo-white.png";
import { useCurrentUser } from "Hooks";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { ProfileMenu } from "./ProfileMenu";
import Button from "@mui/material/Button";

const StyledLink = styled(Link)`
  width: 70%;
  border-bottom: solid white 1px;
  padding: 30px;
  display: block;
  margin: auto;
  font-size: 20px;
  font-family: "Roboto";
  color: white;
`;

const StyledExternalLink = styled.a`
  width: 70%;
  border-bottom: solid white 1px;
  padding: 30px;
  display: block;
  margin: auto;
  font-size: 20px;
  font-family: "Roboto";
  color: white;
  text-decoration: none;
`;

const MobileMenu = styled.div`
  display: block;
  height: 92vh;
  width: 100vw;
  background-color: #262626f7;
  position: absolute;
  left: 0px;
  top: 82px;
`;

const FollowText = styled.p`
  display: inline;
  color: white;
  font-family: "Roboto";
  margin-right: 10px;
`;

const FollowBox = styled.a`
  text-decoration: none;
  display: flex;
  position: relative;
  top: -9px;
  padding: 7px;
  padding-bottom: 8px;
  padding-top: 4px;
  margin-top: 18px;
`;

interface Props {
  setShowLoginModal: React.Dispatch<boolean>;
}

export const MobileBar: React.FC<Props> = ({ setShowLoginModal }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const { currentUser } = useCurrentUser();

  return (
    <>
      <ProfileMenu
        showProfileMenu={showProfileMenu}
        setShowProfileMenu={setShowProfileMenu}
      />
      <Link style={{ display: "flex", textAlign: "left" }} to="/">
        <MainLogo height="60px" width="60px" fill="white" />
      </Link>
      {currentUser ? (
        <Button
          onClick={() => setShowProfileMenu(true)}
          style={{
            margin: "auto 14px auto auto",
            display: "flex",
            alignItems: "center",
            color: "white",
            textTransform: "none",
          }}
        >
          My Profile
          <AccountCircleIcon
            height={20}
            width={20}
            style={{
              display: "flex",
              height: "50px",
              width: "40px",
              marginLeft: "10px",
            }}
          />
        </Button>
      ) : (
        <div
          style={{
            textAlign: "right",
            margin: "auto 22px 22px auto",
            fontFamily: "Roboto",
          }}
          onClick={() => {
            setMenuIsOpen(false);
            setShowLoginModal(true);
          }}
        >
          Login / Signup
        </div>
      )}
      <div
        onClick={() => setMenuIsOpen(oldState => !oldState)}
        style={{ margin: "auto 14px auto 0px" }}
      >
        {menuIsOpen ? <CloseIcon /> : <MenuIcon />}
      </div>
      <Fade in={menuIsOpen}>
        <MobileMenu onClick={() => setMenuIsOpen(false)}>
          {navLinks.map(({ text, url }) =>
            // Handles checking for external link
            typeof url === "string" ? (
              <StyledLink key={text} to={url}>
                {text}
              </StyledLink>
            ) : (
              <StyledExternalLink
                href={url.outsideLink}
                target="_blank"
                rel="noreferrer"
              >
                {text}
              </StyledExternalLink>
            )
          )}
          <div
            onClick={e => e.stopPropagation()}
            style={{
              display: "flex",
              marginTop: "24px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FollowText>Follow us on </FollowText>
            <FollowBox
              target="_blank"
              href="https://gab.com/trdshow"
              style={{
                paddingTop: "8px",
              }}
              rel="noreferrer"
            >
              <img src={gabLogo} height="40" width="65" alt="Gab Logo" />
            </FollowBox>
            <FollowText style={{ marginRight: "10px", marginLeft: "10px" }}>
              and
            </FollowText>
            <FollowBox
              target="_blank"
              href="https://www.gettr.com/user/trdshow"
              style={{
                position: "relative",
                top: "-16px",
              }}
              rel="noreferrer"
            >
              <img
                src={gettrLogoWhite}
                height="40"
                width="88"
                alt="Gettr Logo"
              />
            </FollowBox>
          </div>
        </MobileMenu>
      </Fade>
    </>
  );
};
