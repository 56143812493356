import { StandardGrey } from "colors";
import styled from "styled-components";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import gabLogo from "Images/gab-logo.png";
import gettrIcon from "Images/gettr-icon.png";

const ShareButton = styled.a`
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 1px;
  box-shadow: 1px 1px 3px #80808063;
  background-color: ${StandardGrey};
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 6px;
  width: 28px;
  height: 25px;
`;

export const SocialShareButtons = () => {
  const promoText = `Check out this neat episode I just found on trdshow.net! \n\n ${window.location.href}`;

  const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`;
  const twitterShareLink = `https://twitter.com/intent/tweet?text=${promoText}`;
  const gabShareLink = `https://gab.com/compose?text=${promoText}`;
  const gettrShareLink = `https://gettr.com/share?url=${window.location.href}&text=${promoText}`;

  return (
    <div
      style={{
        flexDirection: "row",
        justifyContent: "left",
        display: "flex",
      }}
    >
      <ShareButton
        style={{ textAlign: "center", padding: "2px" }}
        target="_blank"
        href={gettrShareLink}
      >
        <img
          src={gettrIcon}
          style={{
            height: "18px",
            width: "20px",
            margin: "auto",
            marginTop: "3px",
          }}
          alt="Gettr Logo"
        />
      </ShareButton>
      <ShareButton
        style={{ textAlign: "left" }}
        target="_blank"
        href={gabShareLink}
      >
        <img
          src={gabLogo}
          style={{ height: "16px", width: "24px", marginTop: "4px" }}
          alt="Gab Logo"
        />
      </ShareButton>
      <ShareButton
        style={{ textAlign: "left" }}
        target="_blank"
        href={facebookShareLink}
      >
        <FacebookIcon
          style={{
            color: "#3c3cff",
          }}
        />
      </ShareButton>
      <ShareButton
        style={{ textAlign: "left" }}
        target="_blank"
        href={twitterShareLink}
      >
        <TwitterIcon
          style={{
            color: "#4accf7",
          }}
        />
      </ShareButton>
    </div>
  );
};
