import styled from "styled-components";
import { SlateGray, GlassGrey } from "colors";

export const CardWrapper = styled.div`
  border-radius: 12px;
  height: 325px;
  width: inherit;
  position: relative;
  display: flex;
  justify-content: center;
  box-shadow: #00000061 1px 1px 11px 1px;
  &:hover {
    cursor: pointer;
  }
`;

export const TitleText = styled.h3`
  font-family: "Roboto";
  font-weight: 400;
  text-align: left;
  margin: 0px;
  font-size: 15px;
  margin-top: 5px;
`;

export const LowerSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0px;
  padding: 11px;
  position: absolute;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: ${SlateGray};
  color: white;
  height: 103px;
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-family: "Roboto";
  margin-top: 12px;
  font-size: 11.5px;
  font-weight: 100;
  letter-spacing: 1px;
  flex-wrap: wrap;
  line-height: 18px;
  align-items: center;
  height: inherit;
`;

export const TopHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  justify-content: space-between;
  font-family: "Roboto";
  font-size: 14px;
  background-color: ${SlateGray};
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  color: white;
`;

export const MiddleSectionWrapper = styled.div<{ backgroundColors: string[] }>`
  height: 144px;
  background-color: ${({ backgroundColors }) => backgroundColors[0]};
  background-image: radial-gradient(
      at 47% 33%,
      ${({ backgroundColors }) => backgroundColors[1]} 0px,
      #0000002b 59%
    ),
    radial-gradient(at 82% 65%, rgb(17 25 39 / 41%) 0px, #00000012 55%);
  width: "100%";
  text-align: center;
  margin: auto;
  box-shadow: inset #00000078 0px 0px 20px 2px;
`;

export const LowerSectionDetails = styled.div`
  position: relative;
  bottom: 10px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: right;
  color: ${GlassGrey};
`;

export const EpisodeTypeWrapper = styled.div`
  display: flex;
  float: right;
  position: relative;
  width: fit-content;
  font-family: Roboto;
  letter-spacing: 1px;
`;
