import { GeneralPageHeader, Navbar, Sitemap } from "Components";
import { SeriesList } from "./SeriesList";

export const SeriesLibraryPage = () => (
  <>
    <Navbar />
    <GeneralPageHeader
      mainHeading="Series Library"
      subHeading="The Reformed Dissenters"
    />
    <div style={{ backgroundColor: "white" }}>
      <SeriesList />
    </div>
    <Sitemap />
  </>
);
