interface Link {
  url: string | { outsideLink: string };
  text: string;
}

export const navLinks: Link[] = [
  { url: "/", text: "Home" },
  { url: "/about", text: "About" },
  { url: "/episodes", text: "Episodes" },
  { url: "/series-library", text: "Series" },
  { url: "/refer", text: "Referral Program" },
  {
    url: { outsideLink: "https://thereformeddissenters.myshopify.com/" },
    text: "Merch Store",
  },
];
