import { Mutation_Claim_Free_Merch } from "operations";
import { useMutation } from "@apollo/client";
import { Claim_Free_Merch, Claim_Free_MerchVariables } from "Types";

export const useClaimFreeMerch = () => {
  const [claimFreeMerch, { data, loading: claimFreeMerchIsLoading }] =
    useMutation<Claim_Free_Merch, Claim_Free_MerchVariables>(
      Mutation_Claim_Free_Merch
    );

  return { claimFreeMerch, claimFreeMerchIsLoading, data };
};
