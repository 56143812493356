import React from "react";
import {
  Navbar,
  GeneralPageHeader,
  Sitemap,
  SignupForm,
  Button,
  ColorBox,
  PointsChip,
} from "Components";
import {
  TopSynopsis,
  SectionHeader,
  ColorBoxesWrapper,
  ColorBoxText,
  MerchName,
} from "./elements";
import Box from "@mui/material/Box";
import { useCurrentUser } from "Hooks";
import { useNavigate } from "react-router-dom";

export const ReferPage: React.FC = () => {
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();

  const navigateToProfile = () => navigate("/profile");

  return (
    <>
      <Navbar />
      <GeneralPageHeader
        mainHeading="Refer a friend"
        subHeading="The Reformed Dissenters"
      />
      <div style={{ backgroundColor: "white" }}>
        <Box
          style={{ margin: "auto" }}
          maxWidth={{ xs: "400px", md: "600px" }}
          marginBottom={{ xs: "40px", md: "55px" }}
        >
          <TopSynopsis>
            We'd love it if you could help us get the word out about The
            Reformed Dissenters. So, we've put together a program to help you
            earn points towards free merch every time you share TRD with a
            friend.
          </TopSynopsis>
        </Box>

        <Box
          textAlign={{ xs: "center", md: "left" }}
          style={{ margin: "auto", maxWidth: "590px" }}
        >
          <SectionHeader>How it works</SectionHeader>
        </Box>

        <ColorBoxesWrapper>
          <ColorBox bgColor="blue">
            <ColorBoxText>1. Share TRD</ColorBoxText>
            <img
              style={{ marginTop: "-30px" }}
              height="145px"
              width="145px"
              src="https://trd-show.s3.amazonaws.com/referral-program/refer-friend-button.png"
              alt=""
            />
          </ColorBox>
          <ColorBox bgColor="yellow">
            <ColorBoxText>2. Earn Points</ColorBoxText>
            <img
              height="150px"
              width="156px"
              style={{ marginTop: "-49px" }}
              src="https://trd-show.s3.amazonaws.com/referral-program/points-bubbles.png"
              alt=""
            />
          </ColorBox>
          <ColorBox bgColor="green">
            <ColorBoxText>3. Exchange for Merch</ColorBoxText>
            <div style={{ display: "flex" }}>
              <img
                height="85px"
                width="94px"
                style={{ position: "relative", top: "15px" }}
                alt=""
                src="https://trd-show.s3.amazonaws.com/merch-images/trd-mug.png"
              />
              <img
                height="95px"
                width="95px"
                style={{
                  zIndex: 999,
                  position: "relative",
                  bottom: "2px",
                  left: "-32px",
                }}
                alt=""
                src="https://trd-show.s3.amazonaws.com/merch-images/trd-teeshirt.png"
              />
              <img
                height="60px"
                width="60px"
                style={{ position: "relative", bottom: "-32px", left: "-57px" }}
                alt=""
                src="https://trd-show.s3.amazonaws.com/merch-images/trd-sticker.png"
              />
            </div>
          </ColorBox>
        </ColorBoxesWrapper>

        <Box
          textAlign={{ xs: "center", md: "left" }}
          style={{ margin: "auto", maxWidth: "590px", marginTop: "50px" }}
        >
          <SectionHeader>What can you earn?</SectionHeader>
        </Box>

        <ColorBoxesWrapper>
          <div>
            <ColorBox bgColor="green">
              <PointsChip>7pts</PointsChip>
              <img
                height="135px"
                width="135px"
                style={{ marginTop: "-32px" }}
                alt=""
                src="https://trd-show.s3.amazonaws.com/merch-images/trd-sticker.png"
              />
            </ColorBox>
            <MerchName>Sticker</MerchName>
          </div>
          <div>
            <ColorBox bgColor="blue">
              <PointsChip>17pts</PointsChip>
              <img
                height="150px"
                width="150px"
                style={{ marginTop: "-35px" }}
                alt=""
                src="https://trd-show.s3.amazonaws.com/merch-images/trd-mug.png"
              />
            </ColorBox>
            <MerchName>Mug</MerchName>
          </div>
          <div>
            <ColorBox bgColor="yellow">
              <PointsChip>27pts</PointsChip>
              <img
                height="132px"
                width="132px"
                style={{ marginTop: "-32px" }}
                alt=""
                src="https://trd-show.s3.amazonaws.com/merch-images/trd-teeshirt.png"
              />
            </ColorBox>
            <MerchName>Tee Shirt</MerchName>
          </div>
        </ColorBoxesWrapper>

        <Box maxWidth={{ xs: "350px", md: "600px" }} style={{ margin: "auto" }}>
          {currentUser ? (
            <Box
              marginTop={{ xs: "30px", md: "60px" }}
              marginBottom={{ xs: "30px", md: "60px" }}
              style={{
                fontFamily: "Roboto",
                fontSize: "20px",
                maxWidth: "370px",
                textAlign: "center",
                marginRight: "auto",
                marginLeft: "auto",
              }}
            >
              To view your referral points and begin earning more, head to your
              profile page:{" "}
              <Button
                style={{ height: "40px", marginTop: "20px" }}
                onClick={navigateToProfile}
              >
                Go to Profile
              </Button>
            </Box>
          ) : (
            <SignupForm />
          )}
        </Box>
      </div>
      <Sitemap />
    </>
  );
};
