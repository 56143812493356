import { Mutation_Change_User_Password } from "operations";
import { useMutation } from "@apollo/client";
import { Change_User_Password, Change_User_PasswordVariables } from "Types";

export const useChangeUserPassword = () => {
  const [changeUserPassword, { data, loading: changeUserPasswordIsLoading }] =
    useMutation<Change_User_Password, Change_User_PasswordVariables>(
      Mutation_Change_User_Password
    );

  return {
    changeUserPassword,
    passwordUpdateMessage: data,
    changeUserPasswordIsLoading,
  };
};
