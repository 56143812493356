import { CurrentUserContext } from "Components";
import { useGetUserData } from "Hooks";
import { useContext, useEffect } from "react";
import { User } from "Types";
import posthog from "posthog-js";

// –––| LOCALSTORAGE HANDLERS |–––

const getUserFromLocalStorage = () => {
  const localStorageUser = localStorage.getItem("currentUser");

  if (localStorageUser && localStorageUser.length) {
    return JSON.parse(localStorageUser) as User;
  }

  return null;
};

const writeUserToLocalStorage = (userData: User) => {
  localStorage.setItem("currentUser", JSON.stringify(userData));
};

// –––| REACT STATE HANDLERS |–––

export const useCurrentUser = () => {
  const currentUserContextData = useContext(CurrentUserContext);
  const {
    getUserData,
    getUserDataIsLoading,
    data: fetchedUserData,
    refetch,
  } = useGetUserData();

  const localStorageUserData = getUserFromLocalStorage();

  useEffect(() => {
    if (localStorageUserData) {
      getUserData({ variables: { uid: localStorageUserData.uid } });
      if (currentUserContextData.setCurrentUser) {
        currentUserContextData.setCurrentUser(getUserFromLocalStorage());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      fetchedUserData &&
      fetchedUserData.getUserData &&
      !getUserDataIsLoading
    ) {
      writeUserToLocalStorage(fetchedUserData.getUserData);

      posthog.identify(fetchedUserData.getUserData.email);

      if (currentUserContextData.setCurrentUser) {
        currentUserContextData.setCurrentUser(fetchedUserData.getUserData);
      }
    }
    // if (
    //   !fetchedUserData &&
    //   !getUserDataIsLoading &&
    //   getUserFromLocalStorage()
    // ) {
    //   logout();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedUserData, getUserDataIsLoading]);

  if (
    !currentUserContextData.currentUser &&
    !currentUserContextData.setCurrentUser
  ) {
    return { logout: () => {} };
  }

  const { currentUser, setCurrentUser } = currentUserContextData;

  const handleLocalLoginState = ({ ...user }: User) => {
    if (setCurrentUser) setCurrentUser({ ...user });
    writeUserToLocalStorage({ ...user });
  };

  const logout = () => {
    if (setCurrentUser) setCurrentUser(null);
    localStorage.removeItem("currentUser");
  };

  return { handleLocalLoginState, currentUser, logout, refetch };
};
