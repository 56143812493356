import Box from "@mui/material/Box";
import { Button } from "Components";
import { useFormik } from "formik";
import { useChangeUserPassword, useCurrentUser } from "Hooks";
import React, { useState } from "react";
import { AdjustedInput, InputLabel } from "../elements";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { InputError } from "Components/FormComponents";

const validationSchema = Yup.object({
  currentPassword: Yup.string().required("Please enter your current password"),
  newPassword: Yup.string().required("Please enter your new password"),
});

export const UpdatePasswordFields: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { currentUser } = useCurrentUser();

  const { changeUserPassword, passwordUpdateMessage } = useChangeUserPassword();

  let message = passwordUpdateMessage
    ? passwordUpdateMessage.changeUserPassword.message
    : null;

  if (message?.includes("auth/wrong-password"))
    message =
      "Error: Wrong password! Please reenter your current password and try again.";

  const {
    handleChange,
    values: { currentPassword, newPassword },
    errors,
    handleBlur,
    touched,
    setTouched,
    validateForm,
    resetForm,
  } = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
    },
    validationSchema,
    onSubmit: () => undefined,
  });

  if (!currentUser) return null;

  const handleFormSubmit = () => {
    setTouched({
      currentPassword: true,
      newPassword: true,
    });
    (async () => {
      const formErrors = await validateForm();
      if (!formErrors.currentPassword && !formErrors.newPassword) {
        setLoading(true);
        await changeUserPassword({
          variables: { email: currentUser.email, currentPassword, newPassword },
        });
        resetForm();
        setLoading(false);
      }
    })();
  };

  return (
    <>
      <Box
        justifyContent={{ xs: "center", md: "unset" }}
        style={{
          display: "flex",
          gap: "20px",
          alignContent: "center",
          padding: "15px",
          flexWrap: "wrap",
          flex: 1,
        }}
      >
        <div style={{ flexGrow: "inherit" }}>
          <InputLabel>Current Password</InputLabel>
          <AdjustedInput
            name="currentPassword"
            placeholder="Current Password"
            type="password"
            value={currentPassword}
            disableUnderline
            onChange={handleChange}
            onBlur={handleBlur}
            style={{ width: "100%" }}
          />
          {touched.currentPassword && errors.currentPassword && (
            <InputError>{errors.currentPassword}</InputError>
          )}
        </div>
        <div style={{ flexGrow: "inherit" }}>
          <InputLabel>New Password</InputLabel>
          <AdjustedInput
            name="newPassword"
            placeholder="New Password"
            type="password"
            value={newPassword}
            disableUnderline
            onChange={handleChange}
            onBlur={handleBlur}
            style={{ width: "100%" }}
          />
          {touched.newPassword && errors.newPassword && (
            <InputError>{errors.newPassword}</InputError>
          )}
        </div>
        <Box marginTop={{ xs: "0px", md: "18px" }}>
          <Button onClick={handleFormSubmit} style={{ height: "34px" }}>
            {loading ? (
              <CircularProgress style={{ color: "white" }} size={20} />
            ) : (
              "Update Password"
            )}
          </Button>
        </Box>
      </Box>
      {passwordUpdateMessage && (
        <div
          style={{
            fontFamily: "Roboto",
            fontSize: "16px",
            marginTop: "12px",
            color: message?.includes("Error") ? "red" : "green",
          }}
        >
          {message}
        </div>
      )}
    </>
  );
};
