import {
  AboutPage,
  EpisodePage,
  EpisodesPage,
  FourOhFourPage,
  Homepage,
  NewsletterPage,
  ProfilePage,
  ReferPage,
  ReferralPage,
  SeriesLibraryPage,
  SeriesPage,
} from "Pages";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { CurrentUserProvider, ScrollToTop } from "Components";
import posthog from "posthog-js";

const serverUri =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4000/"
    : "https://the-reformed-dissenters.herokuapp.com/";

export const client = new ApolloClient({
  uri: serverUri,
  cache: new InMemoryCache(),
});

if (!window.location.href.includes("localhost")) {
  posthog.init("phc_rb6kVW3StjcFK1al0VyYrhKbLpiw7AMLwOfNhq71rjm", {
    api_host: "https://app.posthog.com",
  });
}

const App: React.FC = () => (
  <ApolloProvider client={client}>
    <CurrentUserProvider>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/refer" element={<ReferPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/referral" element={<ReferralPage />} />
          <Route path="/newsletter" element={<NewsletterPage />} />
          <Route path="/episodes" element={<EpisodesPage />} />
          <Route path="/episode/:id" element={<EpisodePage />} />
          <Route path="/series-library" element={<SeriesLibraryPage />} />
          <Route path="/series/:id" element={<SeriesPage />} />
          <Route path="*" element={<FourOhFourPage />} />
        </Routes>
      </Router>
    </CurrentUserProvider>
  </ApolloProvider>
);

export default App;
