import Box from "@mui/material/Box";
import {
  GeneralPageHeader,
  LoginRegisterForm,
  Navbar,
  Sitemap,
} from "Components";
import React from "react";
import { FormWrapper } from "./elements";
import { MerchBoxes } from "./MerchBoxes";
import { useNavigate } from "react-router-dom";

export const ReferralPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <GeneralPageHeader
        mainHeading="Referral Program"
        subHeading="Welcome to The Reformed Dissenters!"
      />
      <Box style={{ paddingTop: "30px", backgroundColor: "white" }}>
        <div
          style={{
            margin: "auto",
            textAlign: "center",
            marginBottom: "60px",
            fontFamily: "Roboto",
            fontSize: "20px",
            paddingRight: "20px",
            paddingLeft: "20px",
            maxWidth: "500px",
          }}
        >
          Register a new account to start earning points towards free merch.
          <br />
          <br />
          <div style={{ fontSize: "18px" }}>
            (We'll give you an extra point for signing up with a referral link!)
          </div>
        </div>
        <MerchBoxes />
        <FormWrapper
          marginBottom={{ xs: "0px", md: "34px" }}
          paddingBottom={{ xs: "50px", md: "30px" }}
          paddingTop={{ xs: "50px", md: "30px" }}
        >
          <LoginRegisterForm
            longFieldsWidth
            formHeader="Register a new account"
            showLoginToggle={false}
            onComplete={() => navigate("/profile")}
          />
        </FormWrapper>
      </Box>
      <Sitemap />
    </>
  );
};
