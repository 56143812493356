import { Button } from "Components";
import CircularProgress from "@mui/material/CircularProgress";
import { useGetEpisodesList } from "Hooks";
import React, { useEffect, useState } from "react";
import { EpisodesGrid } from "./EpisodesGrid";
import { SearchBarAndDropDownMenu } from "./SearchBarAndDropDownMenu";
import { Episode, SearchByOptions } from "Types";
import { DarkBackground } from "colors";

export const EpisodeFinder: React.FC = () => {
  const [searchText, setSearchText] = useState("");
  const [episodesList, setEpisodesList] = useState<Episode[]>([]);
  const [searchByFilter, setSearchByFilter] = useState<SearchByOptions>(
    SearchByOptions.titleDescription
  );

  // State to handle search stages
  // const [searchMetaData, setSearchMetaData] = useState<{
  //   hasCompletedSearch: boolean;
  //   searchStage: "titleDescription" | "tags";
  // }>();

  // Handle state and search logic here and pass to SearchBarAndDropDownMenu
  const {
    getEpisodesList,
    data,
    getEpisodesListIsLoading,
    refetch: refetchEpisodesList,
  } = useGetEpisodesList();

  const hasAllEpisodes =
    episodesList.length === data?.getEpisodesList.totalEpisodesCount;

  useEffect(() => {
    if (searchText.length) {
      setEpisodesList([]);
      const handler = setTimeout(() => {
        getEpisodesList({
          variables: { textFilter: searchText, searchBy: searchByFilter },
        });
        // setSearchMetaData()
      }, 500);

      return () => clearTimeout(handler);
    }

    if (searchText.length === 0) {
      setEpisodesList([]);
      getEpisodesList({
        variables: { getTotal: true, textFilter: "", searchBy: searchByFilter },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, searchByFilter]);

  // Handle populating local state when fetched list changes
  useEffect(() => {
    const fetchedEpisodesList = data?.getEpisodesList.episodes;
    if (fetchedEpisodesList && !getEpisodesListIsLoading) {
      setEpisodesList(currentList =>
        currentList.concat(...fetchedEpisodesList)
      );
    }
  }, [data, getEpisodesListIsLoading]);

  const handleLoadMore = () => {
    getEpisodesList({ variables: { limit: 8, skip: episodesList.length } });
  };

  return (
    <div
      style={{
        margin: "auto",
        paddingBottom: "80px",
        paddingTop: "40px",
        textAlign: "center",
        minHeight: "350px",
        backgroundColor: DarkBackground,
      }}
    >
      <SearchBarAndDropDownMenu
        refetchEpisodesList={refetchEpisodesList}
        searchText={searchText}
        setSearchText={setSearchText}
        searchByFilter={searchByFilter}
        setSearchByFilter={setSearchByFilter}
        noResultsFound={
          !data?.getEpisodesList.episodes?.length && !getEpisodesListIsLoading
        }
      />

      {!episodesList.length && getEpisodesListIsLoading ? (
        <CircularProgress style={{ color: "white", marginTop: 60 }} size={40} />
      ) : null}

      {!data?.getEpisodesList.episodes?.length && !getEpisodesListIsLoading ? (
        <div
          style={{
            fontFamily: "Roboto",
            marginTop: "40px",
            fontSize: "20px",
            color: "white",
          }}
        >
          No results found...
        </div>
      ) : null}

      {episodesList && episodesList.length ? (
        <>
          <EpisodesGrid episodes={episodesList} />
          {!searchText && !getEpisodesListIsLoading && !hasAllEpisodes && (
            <Button onClick={handleLoadMore}>Load More</Button>
          )}
        </>
      ) : null}
    </div>
  );
};
