import { Navbar, GeneralPageHeader, Sitemap } from "Components";
import React from "react";
import { EpisodeFinder } from "./EpisodeFinder";

export const EpisodesPage: React.FC = () => (
  <>
    <Navbar />
    <GeneralPageHeader
      mainHeading="Episodes"
      subHeading="The Reformed Dissenters"
    />
    <EpisodeFinder />
    <Sitemap />
  </>
);
