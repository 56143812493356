import Box from "@mui/material/Box";
import { ColorBox, PointsChip } from "Components";
import { MerchName } from "Pages/ReferPage/elements";
import React from "react";

export const MerchBoxes: React.FC = () => (
  <>
    <Box
      flexDirection={{ xs: "column", md: "row" }}
      alignItems={{ xs: "center", md: "normal" }}
      style={{
        display: "flex",
        gap: "10px",
        marginBottom: "60px",
        justifyContent: "center",
      }}
    >
      <div>
        <ColorBox bgColor="green">
          <PointsChip>7pts</PointsChip>
          <img
            height="135px"
            width="135px"
            style={{ marginTop: "-32px" }}
            alt=""
            src="https://trd-show.s3.amazonaws.com/merch-images/trd-sticker.png"
          />
        </ColorBox>
        <MerchName>Sticker</MerchName>
      </div>
      <div>
        <ColorBox bgColor="blue">
          <PointsChip>17pts</PointsChip>
          <img
            height="150px"
            width="150px"
            style={{ marginTop: "-35px" }}
            alt=""
            src="https://trd-show.s3.amazonaws.com/merch-images/trd-mug.png"
          />
        </ColorBox>
        <MerchName>Mug</MerchName>
      </div>
      <div>
        <ColorBox bgColor="yellow">
          <PointsChip>27pts</PointsChip>
          <img
            height="132px"
            width="132px"
            style={{ marginTop: "-32px" }}
            alt=""
            src="https://trd-show.s3.amazonaws.com/merch-images/trd-teeshirt.png"
          />
        </ColorBox>
        <MerchName>Tee Shirt</MerchName>
      </div>
    </Box>
  </>
);
