import styled from "styled-components";
import { PaleGrey } from "../../colors";

export const AdHeader = styled.p`
  font: normal normal bold 23px "Roboto";
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  margin: 0px;
`;

export const AdBodyText = styled.p`
  font: normal normal medium 24px;
  font-family: "Roboto";
  letter-spacing: 0px;
  color: #232323;
  max-width: 520px;
`;

export const GreenAdBox = styled.div`
  background: transparent linear-gradient(161deg, #51b47c 0%, #31744e 100%) 0%
    0% no-repeat padding-box;
  background: transparent linear-gradient(161deg, #51b47c 0%, #31744e 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 10px 25px #2f455726;
  opacity: 1;
  border-radius: 0px 0px 10px 10px;
  padding: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
`;

export const GreySection = styled.div`
  background-color: ${PaleGrey};
  border-radius: 10px 10px 0px 0px;
  padding: 20px;
  text-align: left;
`;

export const GreySectionSubheading = styled.p`
  margin: 0px;
  font-family: "Roboto";
  font-size: 16px;
  color: black;
  text-align: left;
  margin-top: 15px;
`;
