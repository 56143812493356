import Box from '@mui/material/Box';
import { EpisodeCard } from 'Components';
import { Get_Series_getSeriesList_series } from 'Types';

interface Props {
  episodes: Get_Series_getSeriesList_series['seriesEpisodes'];
}

export const SeriesGridView = ({ episodes }: Props) => (
  <>
    <Box
      padding={{ xs: '50px', md: '150px' }}
      style={{
        minHeight: '350px',
        paddingTop: '50px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '20px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {episodes.map(({ ...episode }) => (
          <EpisodeCard {...episode} />
        ))}
      </div>
    </Box>
  </>
);
