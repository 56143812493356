import styled from "styled-components";

export const VideoIframe = styled.iframe`
  height: 100%;
  width: 100%;
  margin: auto;
  border-radius: 10px;
  border: none;
`;

export const SectionWrapper = styled.div`
  background-color: #403f3f;
  padding: 12px;
  border-radius: 10px;
  color: white;
  font-family: "Roboto";
`;

export const SectionHeader = styled.div`
  font-family: "Roboto";
  font-size: 18px;
  padding-bottom: 18px;
  text-align: left;
`;

export const InfoProperty = styled.div`
  font-weight: 600;
  display: flex;
  padding-right: 5px;
  letter-spacing: 1px;
`;

export const InfoPropertyWrapper = styled.div`
  flex-direction: row;
  display: flex;
  font-family: Roboto;
  font-size: 14px;
  padding-bottom: 8px;
`;

export const EpisodeTitle = styled.div`
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 45px;
  margin-top: 45px;
  text-align: left;
  color: white;
`;

export const TabSectionHeader = styled.h2`
  font-family: Roboto;
  font-weight: normal;
  text-align: left;
  margin-top: 40px;
  margin-bottom: 40px;
  color: white;
`;

export const TabSectionContent = styled.div`
  font-family: Roboto;
  text-align: left;
  line-height: 26px;
  margin-top: 20px;
  white-space: pre-line;
  color: #c8c8c8;
  font-size: 16.5px;
`;

export const EpisodeViewsText = styled.div`
  font-family: "Roboto";
  color: white;
  font-size: 0.8rem;
  text-align: left;
  margin-top: 10px;
`;
