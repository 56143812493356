import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { useGetLatestEpisode } from "Hooks";
import { MainLogo } from "Icons";
import { useEffect } from "react";
import { getFormattedDate } from "utilities";
import {
  BackgroundGradient,
  EpisodeDate,
  EpisodeTitle,
  SectionHeader,
  TagsWrapper,
  ViewNowButton,
} from "./elements";
import { EpisodeType } from "Types";

export const LatestEpisodeBox = () => {
  const { getLatestEpisode, getLatestEpisodeIsLoading, data } =
    useGetLatestEpisode();

  const navigate = useNavigate();

  useEffect(() => {
    getLatestEpisode();
  }, [getLatestEpisode]);

  const episodes = data?.getEpisodesList.episodes;

  if (
    getLatestEpisodeIsLoading ||
    episodes === null ||
    episodes === undefined
  ) {
    return null;
  }

  const { title, episodeType, publishDate, tags, id } = episodes[0];

  const firstThreeTags = tags?.slice(0, 3);

  const { entireDate } = getFormattedDate(publishDate);

  const backgroundColor: { [key in EpisodeType]: [string, string] } = {
    CURRENT_EVENTS: ["rgb(114 181 167)", "rgb(126 174 166)"],
    LITERATURE_EPISODE: ["rgb(181 174 114)", "rgb(174 166 126)"],
    DISCUSSION_TOPIC: ["rgb(114 154 181)", "rgb(126 153 174)"],
  };

  return (
    <div
      style={{
        paddingTop: "60px",
        paddingBottom: "60px",
      }}
    >
      <SectionHeader>Our latest episode</SectionHeader>
      {getLatestEpisodeIsLoading ? (
        <CircularProgress style={{ color: "black", marginTop: 40 }} size={40} />
      ) : (
        <Box
          style={{
            margin: "auto",
            marginTop: "40px",
            alignItems: "center",
            display: "flex",
          }}
          height={{ xs: "390px", md: "350px" }}
          width="100%"
        >
          <BackgroundGradient
            onClick={() => navigate(`/episode/${id}`)}
            backgroundColors={backgroundColor[episodeType]}
          >
            <Box width={{ xs: "300px", md: "650px" }}>
              <Grid container style={{ padding: "15px" }}>
                <Grid
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                  item
                  xs={12}
                  md={6}
                >
                  <Box fontSize={{ xs: "1.2rem", md: "1.7rem" }}>
                    <EpisodeTitle>{title}</EpisodeTitle>
                  </Box>
                </Grid>
                <Grid
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  item
                  xs={12}
                  md={6}
                >
                  <MainLogo
                    fill="black"
                    style={{
                      height: "110px",
                      width: "auto",
                      position: "relative",
                    }}
                  />
                  <EpisodeDate>{entireDate}</EpisodeDate>
                  <TagsWrapper>
                    {firstThreeTags?.slice(0, 3).map(({ title }, idx) => (
                      <div
                        style={{ marginRight: "2px", marginLeft: "2px" }}
                        key={idx}
                      >
                        {title.toUpperCase()}{" "}
                        {`${idx !== firstThreeTags?.length - 1 ? " • " : ""}`}
                      </div>
                    ))}
                  </TagsWrapper>
                  <ViewNowButton onClick={() => navigate(`/episode/${id}`)}>
                    Watch now
                  </ViewNowButton>
                </Grid>
              </Grid>
            </Box>
          </BackgroundGradient>
        </Box>
      )}
    </div>
  );
};
