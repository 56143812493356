import { Mutation_Update_User_Settings } from "operations";
import { useMutation } from "@apollo/client";
import { Update_User_Settings, Update_User_SettingsVariables } from "Types";

export const useUpdateUserSettings = () => {
  const [updateUserSettings, { data, loading: updateUserSettingsIsLoading }] =
    useMutation<Update_User_Settings, Update_User_SettingsVariables>(
      Mutation_Update_User_Settings
    );

  return {
    updateUserSettings,
    returnedUserData: data,
    updateUserSettingsIsLoading,
  };
};
