import { gql } from "@apollo/client";

export const Fragment_User = gql`
  fragment User on User {
    email
    referralCode
    name
    uid
    referralPoints
    emailIsVerified
  }
`;

export const Fragment_Episode = gql`
  fragment Episode on Episode {
    id
    title
    episodeNumber
    episodeType
    rumbleEmbedLink
    episodeDescription
    episodeLinks
    publishDate
    episodeViews
    episodeThumbnail {
      url
    }
    tags {
      title
    }
  }
`;

export const Fragment_Episode_Card = gql`
  fragment Episode_Card on Episode {
    id
    title
    episodeNumber
    episodeType
    publishDate
    tags {
      title
    }
    episodeThumbnail {
      url
    }
  }
`;

export const Fragment_Server_Message = gql`
  fragment Message on ServerMessage {
    message
  }
`;

export const Mutation_Register_New_User = gql`
  mutation Register_New_User(
    $name: String!
    $email: String!
    $password: String!
    $awardPointToReferrer: AwardPointToReferrerInput
  ) {
    registerNewUser(
      input: {
        name: $name
        email: $email
        password: $password
        awardPointToReferrer: $awardPointToReferrer
      }
    ) {
      ...User
      ...Message
    }
  }
  ${Fragment_User}
  ${Fragment_Server_Message}
`;

export const Query_Get_Current_User = gql`
  query Get_Current_User($email: String!, $password: String!) {
    getCurrentUser(input: { email: $email, password: $password }) {
      ...User
      ...Message
    }
  }
  ${Fragment_User}
  ${Fragment_Server_Message}
`;

export const Query_Get_User_Data = gql`
  query Get_User_Data($uid: String!) {
    getUserData(input: { uid: $uid }) {
      ...User
    }
  }
  ${Fragment_User}
`;

export const Mutation_Update_User_Settings = gql`
  mutation Update_User_Settings($name: String, $email: String, $uid: String!) {
    updateUserSettings(input: { uid: $uid, email: $email, name: $name }) {
      ...User
    }
  }
  ${Fragment_User}
`;

export const Mutation_Change_User_Password = gql`
  mutation Change_User_Password(
    $email: String!
    $currentPassword: String!
    $newPassword: String!
  ) {
    changeUserPassword(
      input: {
        email: $email
        currentPassword: $currentPassword
        newPassword: $newPassword
      }
    ) {
      ...Message
    }
  }
  ${Fragment_Server_Message}
`;

export const Mutation_Claim_Free_Merch = gql`
  mutation Claim_Free_Merch(
    $name: String!
    $uid: String!
    $address: String!
    $merchType: String!
  ) {
    claimFreeMerch(
      input: {
        uid: $uid
        name: $name
        address: $address
        merchType: $merchType
      }
    ) {
      ...Message
    }
  }
  ${Fragment_Server_Message}
`;

export const Mutation_Send_Password_Reset_Email = gql`
  mutation Send_Password_Reset_Email($email: String!) {
    sendPasswordResetEmail(input: { email: $email }) {
      ...Message
    }
  }
  ${Fragment_Server_Message}
`;

export const Query_Get_Episodes_List = gql`
  query Get_Episodes_List(
    $limit: Int
    $textFilter: String
    $episodeId: String
    $skip: Int
    $getTotal: Boolean
    $searchBy: SearchByOptions
  ) {
    getEpisodesList(
      input: {
        limit: $limit
        textFilter: $textFilter
        episodeId: $episodeId
        skip: $skip
        getTotal: $getTotal
        searchBy: $searchBy
      }
    ) {
      episodes {
        ...Episode
      }
      totalEpisodesCount
    }
  }
  ${Fragment_Episode}
`;

export const Query_Get_Latest_Episode = gql`
  query Get_Latest_Episode {
    getEpisodesList(input: { limit: 1 }) {
      episodes {
        id
        title
        episodeNumber
        episodeType
        publishDate
        tags {
          title
        }
      }
    }
  }
`;

export const Query_Get_Series_List = gql`
  query Get_Series_List($limit: Int, $seriesId: String, $skip: Int) {
    getSeriesList(input: { limit: $limit, skip: $skip, seriesId: $seriesId }) {
      series {
        seriesId
        seriesTitle
        seriesSubtitle
        seriesCardImage {
          url
        }
        seriesEpisodes {
          ...Episode_Card
        }
      }
    }
  }
  ${Fragment_Episode_Card}
`;

export const Query_Get_Series = gql`
  query Get_Series($seriesId: String) {
    getSeriesList(input: { seriesId: $seriesId }) {
      series {
        seriesId
        seriesTitle
        seriesSubtitle
        seriesCardImage {
          url
        }
        seriesEpisodes {
          ...Episode
        }
      }
    }
  }
  ${Fragment_Episode}
`;
