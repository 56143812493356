import { Navbar, GeneralPageHeader, Sitemap } from "Components";
import { useCurrentUser } from "Hooks";
import React from "react";
import { ChooseMerchSection } from "./ChooseMerchSection";
import {
  ExplainerText,
  UnverifiedEmailSection,
  UnverifiedEmailStep,
} from "./elements";
import { ReferralInfoSections } from "./ReferralInfoSections";
import { UpdateInfoForm } from "./UpdateInfoForm";

export const ProfilePage: React.FC = () => {
  const { currentUser } = useCurrentUser();

  if (!currentUser)
    return (
      <>
        <Navbar />
        <GeneralPageHeader
          mainHeading="Profile"
          subHeading="Please login or register an account"
        />
        <div style={{ paddingTop: "47vh" }} />
        <Sitemap />
      </>
    );

  return (
    <>
      <Navbar />
      <GeneralPageHeader mainHeading="Profile" subHeading={currentUser?.name} />
      <div style={{ backgroundColor: "white" }}>
        <UpdateInfoForm />
        {!currentUser.emailIsVerified ? (
          <UnverifiedEmailSection>
            You haven't verified your email, yet.{" "}
            <div style={{ fontWeight: 600, display: "inline" }}>
              Until you do, you won't be able to participate in our referral
              program.
            </div>{" "}
            Please check your inbox for an email from us (it might be in your
            spam folder), then:
            <UnverifiedEmailStep style={{ marginTop: "40px" }}>
              1. Click the link in the email
            </UnverifiedEmailStep>
            <UnverifiedEmailStep>2. Refresh this page</UnverifiedEmailStep>
          </UnverifiedEmailSection>
        ) : (
          <>
            <ReferralInfoSections />
            <ExplainerText>
              Your friend will need to verify their email address before you can
              earn points from them. If you don't see a change in your points,
              and you know they've signed up for an account using your link, ask
              them to check their inbox.
            </ExplainerText>
            <ChooseMerchSection />
          </>
        )}
      </div>
      <Sitemap />
    </>
  );
};
