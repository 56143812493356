import {
  FormHeading,
  SectionWrapper,
  StyledInput,
  InputError,
} from "../FormComponents";
import React, { useState } from "react";
import { Button } from "Components";
import * as Yup from "yup";
import { useFormik } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import {
  FormStatus,
  useRegisterErrorHandling,
  useRegisterNewUser,
} from "Hooks";

const validationSchema = Yup.object({
  name: Yup.string().required("Please enter your name"),
  email: Yup.string()
    .email("Invalid email address")
    .required("You must enter an email address"),
  password: Yup.string().required("Please enter your password"),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please reenter your password"),
});

export const SignupForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<FormStatus>(null);

  const {
    handleChange,
    values: { name, email, password, passwordConfirm },
    errors,
    handleBlur,
    touched,
    setTouched,
    validateForm,
    resetForm,
  } = useFormik({
    initialValues: { email: "", name: "", password: "", passwordConfirm: "" },
    validationSchema,
    onSubmit: () => undefined,
  });

  const {
    registerNewUser,
    registerNewUserIsLoading,
    data: registeredUser,
  } = useRegisterNewUser();

  useRegisterErrorHandling({
    registerNewUserIsLoading,
    registeredUser,
    setStatus,
  });

  const handleUserSignup = async () => {
    await registerNewUser({ variables: { name, email, password } });

    resetForm();
    setIsLoading(false);
  };

  const handleFormSubmit = () => {
    setTouched({
      name: true,
      email: true,
      password: true,
      passwordConfirm: true,
    });
    (async () => {
      const formErrors = await validateForm();
      if (
        !formErrors.name &&
        !formErrors.email &&
        !formErrors.password &&
        !formErrors.passwordConfirm
      ) {
        setIsLoading(true);
        handleUserSignup();
      }
    })();
  };

  return (
    <SectionWrapper
      style={{
        borderRadius: "12px",
        marginBottom: "70px",
        textAlign: "center",
      }}
    >
      <FormHeading>Sign-up to start earning</FormHeading>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <StyledInput
          name="name"
          placeholder="Name"
          disableUnderline
          style={{ marginRight: 10 }}
          onChange={handleChange}
          value={name}
          onBlur={handleBlur}
        />
        <StyledInput
          name="email"
          placeholder="Email"
          disableUnderline
          onChange={handleChange}
          value={email}
          onBlur={handleBlur}
        />
      </div>
      {touched.email && errors.email && <InputError>{errors.email}</InputError>}
      {touched.name && errors.name && <InputError>{errors.name}</InputError>}
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
      >
        <StyledInput
          name="password"
          placeholder="Password"
          type="password"
          disableUnderline
          style={{ marginRight: 10 }}
          onChange={handleChange}
          value={password}
          onBlur={handleBlur}
        />
        <StyledInput
          name="passwordConfirm"
          placeholder="Confirm password"
          type="password"
          disableUnderline
          onChange={handleChange}
          value={passwordConfirm}
          onBlur={handleBlur}
        />
      </div>
      {touched.password && errors.password && (
        <InputError>{errors.password}</InputError>
      )}
      {touched.passwordConfirm && errors.passwordConfirm && (
        <InputError>{errors.passwordConfirm}</InputError>
      )}
      {status?.message && (
        <InputError style={{ color: status?.code === 404 ? "red" : "green" }}>
          {status?.message}
        </InputError>
      )}
      <Button
        onClick={handleFormSubmit}
        style={{ margin: "auto", marginTop: "25px" }}
      >
        {isLoading ? (
          <CircularProgress
            style={{ color: "white", marginTop: 10 }}
            size={20}
          />
        ) : (
          "Sign up"
        )}
      </Button>
    </SectionWrapper>
  );
};
