export const DarkGrey = "#444444";
export const DarkBackground = "#272626";
export const SlateGray = "#4f4f4fb0";
export const GlassGrey = "#ffffffc4";
export const StandardGrey = "#dddddd";
export const PaleGrey = "#e6e9eb";
export const LightWarningRed = "#ff9191";
export const SkyBlue = "#008cff";
export const PaleBlue = "#a1f2ff";
export const DarkBlue = "#5fb7ff70"
export const PaleGreen = "#cbefe0";
export const UltraPaleGreen = "#81d8b2";
export const UltraPaleBlue = "#2ae3ff";
export const UltraPaleYellow = "#f5fdca";
export const LemonGreen = "#bacc14";
export const MustardYellow = "#dee681";
