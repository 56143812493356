import CircularProgress from "@mui/material/CircularProgress";
import { DarkBackground } from "colors";
import { GeneralPageHeader, Navbar, Sitemap } from "Components";
import { useGetEpisodesList } from "Hooks";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Episode } from "Types";
import { EpisodeContent } from "./EpisodeContent";

export const EpisodePage: React.FC = () => {
  const [episode, setEpisode] = useState<Episode | null>(null);
  const { id } = useParams();

  const { getEpisodesList, data } = useGetEpisodesList();

  useEffect(() => {
    if (data && data.getEpisodesList && data.getEpisodesList.episodes) {
      setEpisode(data.getEpisodesList.episodes[0]);
    }
  }, [data]);

  useEffect(() => {
    getEpisodesList({ variables: { episodeId: id } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Navbar />
      <GeneralPageHeader
        smallerFont
        mainHeading={!episode ? "Loading..." : "The Reformed Dissenters"}
        subHeading={!episode ? "" : `Episode #${episode.episodeNumber}`}
      />
      {!episode ? (
        <div
          style={{
            width: "100%",
            paddingTop: "60px",
            paddingBottom: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "30vh",
            backgroundColor: DarkBackground,
          }}
        >
          <CircularProgress
            style={{ color: "white", marginTop: 20 }}
            size={40}
          />
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            backgroundColor: DarkBackground,
            top: "-10px",
          }}
        >
          <EpisodeContent episode={episode} />
        </div>
      )}
      <Sitemap />
    </>
  );
};
