import React, { useState } from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import { MainLogo } from "Icons";
import { Link } from "Components";
import gabLogo from "Images/gab-logo.png";
import gettrLogoWhite from "Images/gettr-logo-white.png";
import { navLinks } from "./navLinksData";
import { useCurrentUser } from "Hooks";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { ProfileMenu } from "./ProfileMenu";
import Button from "@mui/material/Button";

const StyledLink = styled(Link)`
  font-family: "Roboto";
  font-size: 16px;
  color: white;
  margin: 10px;
`;

const StyledExternalLink = styled.a`
  font-family: "Roboto";
  font-size: 16px;
  color: white;
  margin: 10px;
  text-decoration: none;
  /* margin-right: 20px; */
`;

const LinksWrapper = styled.div`
  flex: 1;
  max-width: 960px;
  display: flex;
  align-items: center;
  margin: auto;
`;

interface Props {
  setShowLoginModal: React.Dispatch<boolean>;
}

export const DesktopBar: React.FC<Props> = ({ setShowLoginModal }) => {
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const { currentUser } = useCurrentUser();

  return (
    <>
      <ProfileMenu
        showProfileMenu={showProfileMenu}
        setShowProfileMenu={setShowProfileMenu}
      />
      <LinksWrapper>
        <Link style={{ display: "flex", textAlign: "left" }} to="/">
          <MainLogo height="60px" width="60px" fill="white" />
        </Link>
        {/* SPACER */}
        <Box width={{ md: "5px" }} style={{ flexGrow: "inherit" }} />
        <div style={{ textAlign: "right" }}>
          {navLinks.map(({ text, url }) =>
            // Handles checking for external link
            typeof url === "string" ? (
              <StyledLink style={{ marginRight: "20px" }} key={text} to={url}>
                {text}
              </StyledLink>
            ) : (
              <StyledExternalLink
                href={url.outsideLink}
                target="_blank"
                rel="noreferrer"
              >
                {text}
              </StyledExternalLink>
            )
          )}
        </div>
        {/* SPACER */}
        <Box width={{ md: "40px" }} style={{ flexGrow: "inherit" }} />
        <a
          target="_blank"
          href="https://gab.com/trdshow"
          style={{ paddingTop: 2, marginRight: "20px" }}
          rel="noreferrer"
        >
          <img src={gabLogo} height="25" width="40" alt="Gab Logo" />
        </a>
        <a
          target="_blank"
          href="https://www.gettr.com/user/trdshow"
          style={{ paddingTop: 2, marginRight: "20px", marginTop: "-10px" }}
          rel="noreferrer"
        >
          <img src={gettrLogoWhite} height="25" width="55" alt="Gab Logo" />
        </a>
        {currentUser ? (
          <Button
            onClick={() => setShowProfileMenu(true)}
            style={{
              margin: "auto 14px auto auto",
              display: "flex",
              alignItems: "center",
              color: "white",
              textTransform: "none",
            }}
          >
            My Profile
            <AccountCircleIcon
              height={20}
              width={20}
              style={{
                display: "flex",
                height: "50px",
                width: "40px",
                marginLeft: "10px",
              }}
            />
          </Button>
        ) : (
          <div
            style={{
              textAlign: "right",
              margin: "auto 22px 22px auto",
              fontFamily: "Roboto",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowLoginModal(true);
            }}
          >
            Login / Signup
          </div>
        )}
      </LinksWrapper>
    </>
  );
};
