import {
  LemonGreen,
  PaleGrey,
  UltraPaleBlue,
  UltraPaleGreen,
  UltraPaleYellow,
  DarkBlue,
} from "../colors";
import styled from "styled-components";
import Button from "@mui/material/Button";

export const ColorBox = styled.div<{ bgColor: "green" | "yellow" | "blue" }>`
  border-radius: 10px;
  height: 140px;
  width: 190px;
  background-color: ${({ bgColor }) => {
    if (bgColor === "green") return UltraPaleGreen;
    if (bgColor === "yellow") return UltraPaleYellow;
    if (bgColor === "blue") return UltraPaleBlue;
  }};
  text-align: center;
`;

export const PointsChip = styled.div`
  border-radius: 50%;
  height: 36px;
  width: 36px;
  color: white;
  background-color: ${LemonGreen};
  text-align: center;
  font-size: 12.5px;
  font-family: "Roboto";
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  right: -145px;
  top: 7px;
  text-shadow: 2px 1px 3px #00000096;
`;

export const ModalBackground = styled.div`
  height: 100%;
  width: 100%;
  background-color: #80808085;
  z-index: 9999;
  top: 0;
  position: fixed;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const ModalWrapper = styled.div`
  box-shadow: #00000047 0px 0px 14px 1px;
  width: 300px;
  max-height: 426px;
  overflow: scroll;
  min-height: 200px;
  border-radius: 10px;
  background-color: ${PaleGrey};
  padding: 20px;
`;

export const TagPill = styled.div`
  padding: 5px 9px;
  color: white;
  background-color: ${DarkBlue};
  width: fit-content;
  border-radius: 5px;
  margin-right: 7px;
  margin-top: 7px;
  font-family: "Roboto";
  letter-spacing: 1px;
  text-shadow: 0px 0px 3px #686868;
  font-size: 16px;
`;

export const BackButton = styled(Button)`
  && {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 14px;
    text-transform: uppercase;
    font-family: "Roboto";
    background-color: white;
    width: fit-content;
    padding: 5px 10px 4px 7px;
    border-radius: 10px;
    cursor: pointer;
    color: black;
  }
`;

export const DefaultPageWrapper = styled.div<{
  topPaddingFalse?: boolean;
  bottomPaddingFalse?: boolean;
}>`
  text-align: center;
  position: relative;
  padding-bottom: 20vh;
  padding-top: 30px;
  ${({ topPaddingFalse }) => topPaddingFalse && "padding-top: 0px;"}
  ${({ bottomPaddingFalse }) => bottomPaddingFalse && "padding-bottom: 0px;"}
  min-height: 88vh;
`;
