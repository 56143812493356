import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Button, ColorBox, PointsChip } from "Components";
import { FormHeading } from "Components/FormComponents";
import { merchSelectionsData, MerchOptions } from "./merchSelectionsData";
import { useCurrentUser } from "Hooks";
import { MerchBoxOverlay, MerchConfirmationMessage } from "./elements";
import { ClaimMerchModal } from "./ClaimMerchComponents";
import Fade from "@mui/material/Fade";

export const ChooseMerchSection: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [merchType, setMerchType] = useState<MerchOptions | null>(null);
  const [merchSelectionMessage, setMerchSelectionMessage] = useState<
    null | string
  >(null);
  const { currentUser } = useCurrentUser();

  if (!currentUser) return null;

  const referralPointsCount = currentUser.referralPoints;

  const handleClose = ({ message }: { message?: string }) => {
    message && setMerchSelectionMessage(message);
    setShowModal(false);
  };

  const handleButtonClick = (merchName: MerchOptions) => {
    setShowModal(true);
    setMerchType(merchName);
  };

  return (
    <>
      {merchType && (
        <ClaimMerchModal
          merchType={merchType}
          showModal={showModal}
          handleClose={handleClose}
        />
      )}
      <Box maxWidth={{ xs: "570px" }} style={{ margin: "auto" }}>
        <FormHeading>Choose your free merch</FormHeading>
        <Fade in={Boolean(merchSelectionMessage)}>
          <MerchConfirmationMessage>
            {merchSelectionMessage}
          </MerchConfirmationMessage>
        </Fade>
        <Box
          flexDirection={{ xs: "column", md: "row" }}
          alignItems={{ xs: "center", md: "normal" }}
          style={{
            display: "flex",
            gap: "10px",
            marginBottom: "70px",
          }}
        >
          {merchSelectionsData.map(
            ({ merchTitle, merchImage, merchPoints, bgColor }) => (
              <div key={merchTitle} style={{ textAlign: "center" }}>
                <ColorBox style={{ width: "182px" }} bgColor={bgColor}>
                  <MerchBoxOverlay
                    merchPoints={merchPoints}
                    referralPointsCount={referralPointsCount}
                  />
                  <PointsChip style={{ right: "-140px" }}>
                    {merchPoints}pts
                  </PointsChip>
                  <img
                    height={merchImage.height}
                    width={merchImage.width}
                    style={{ marginTop: merchImage.marginTop }}
                    alt=""
                    src={merchImage.src}
                  />
                </ColorBox>
                <Button
                  disabled={!(referralPointsCount >= merchPoints)}
                  style={{ margin: "auto", marginTop: "20px" }}
                  onClick={() => handleButtonClick(merchTitle)}
                >
                  Claim your {merchTitle}
                </Button>
              </div>
            )
          )}
        </Box>
      </Box>
    </>
  );
};
