import Fade from "@mui/material/Fade";
import { LoginRegisterForm, ModalBackground, ModalWrapper } from "Components";
import React from "react";

interface Props {
  showModal: boolean;
  handleClose(): any;
}

export const LoginRegisterModal: React.FC<Props> = ({
  showModal,
  handleClose,
}) => (
  <Fade in={showModal}>
    <ModalBackground onClick={handleClose}>
      <ModalWrapper onClick={e => e.stopPropagation()}>
        <LoginRegisterForm onComplete={handleClose} />
      </ModalWrapper>
    </ModalBackground>
  </Fade>
);
