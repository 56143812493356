import { Query_Get_Series } from "operations";
import { ApolloQueryResult, useLazyQuery } from "@apollo/client";
import { Get_Series, Get_SeriesVariables } from "Types";

export type SeriesRefetch = (
  variables?: Partial<Get_SeriesVariables> | undefined
) => Promise<ApolloQueryResult<Get_Series>>;

export const useGetSeries = () => {
  const [getSeries, { data, loading: getSeriesIsLoading, refetch }] =
    useLazyQuery<Get_Series, Get_SeriesVariables>(Query_Get_Series);

  return { getSeries, getSeriesIsLoading, data, refetch };
};
