import styled from "styled-components";
import Select from "@mui/material/Select";

export const StyledSelect = styled(Select)`
  width: 100%;
  border: none;
  background-color: white;
  height: 32px;
  margin-top: 10px;
  width: 332px;
  border-radius: 8px;
  padding: 6px;
  padding-left: 10px;
`;

export const StyledMenuItem = styled.div`
  background-color: white;
  text-align: left;
  margin: auto;
  font-size: 14px;
  font-family: "Roboto";
`;
