import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { DarkBlue } from "colors";
import { useState } from "react";
import { TabSectionContent, TabSectionHeader } from "./elements";

interface Props {
  description: string;
  links?: string | null;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

type nodeType = "hyperlink" | "text";

interface NodeShape {
  nodeType: nodeType;
  data?: {
    uri: string;
  };
  value?: string;
  content?: {
    nodeType: nodeType;
    value: string;
  }[];
}

export const DescriptionLinkSection: React.FC<Props> = ({
  description,
  links,
}) => {
  const [value, setValue] = useState(0);

  let parsedLinks: React.ReactNode[] = [];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const linksIsNull = links === "null";

  if (links && !linksIsNull) {
    const parsedData = JSON.parse(links).json.content;

    parsedData.forEach(({ content }: any) => {
      content.forEach((node: NodeShape) => {
        if (node.nodeType === "text" && node.value) {
          parsedLinks.push(<div>{node.value || ""}</div>);
        }
        if (node.nodeType === "hyperlink" && node.content) {
          parsedLinks.push(
            <div style={{ marginBottom: "20px", marginTop: "25px" }}>
              <a
                style={{ color: DarkBlue }}
                href={node.data?.uri}
                target="_blank"
                rel="noreferrer"
              >
                {node.content[0].value}
              </a>
            </div>
          );
        }
      });
    });
  }

  const getTabStyles = (selected: boolean) => {
    const commonStyles = { fontFamily: "Roboto", letterSpacing: "1.5px" };
    if (selected) return { color: "white", ...commonStyles };
    if (!selected) return { color: "grey", ...commonStyles };
  };

  return (
    <>
      {!linksIsNull && (
        <Box
          style={{ borderBottom: 1, borderColor: "grey", marginTop: "20px" }}
        >
          <Tabs value={value} onChange={handleChange}>
            <Tab style={getTabStyles(value === 0)} label="Description" />
            <Tab style={getTabStyles(value === 1)} label="Episode Links" />
          </Tabs>
        </Box>
      )}
      <TabPanel value={value} index={0}>
        <TabSectionHeader>Description</TabSectionHeader>
        <TabSectionContent
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TabSectionHeader>Episode Links</TabSectionHeader>
        <TabSectionContent>{parsedLinks}</TabSectionContent>
      </TabPanel>
    </>
  );
};
