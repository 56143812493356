import { Mutation_Register_New_User } from "operations";
import { useMutation } from "@apollo/client";
import { Register_New_User, Register_New_UserVariables } from "Types";

export const useRegisterNewUser = () => {
  const [registerNewUser, { data, loading: registerNewUserIsLoading }] =
    useMutation<Register_New_User, Register_New_UserVariables>(
      Mutation_Register_New_User,
      { fetchPolicy: "network-only" }
    );

  return { registerNewUser, registerNewUserIsLoading, data };
};
