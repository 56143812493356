import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { DarkGrey } from "../../colors";
import Box from "@mui/material/Box";
import { DesktopBar } from "./DesktopBar";
import { MobileBar } from "./MobileBar";
import { LoginRegisterModal } from "Components";

export const Navbar: React.FC = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleModalClose = () => setShowLoginModal(false);

  return (
    <>
      <LoginRegisterModal
        showModal={showLoginModal}
        handleClose={handleModalClose}
      />
      <AppBar position="fixed">
        <Toolbar
          style={{
            backgroundColor: DarkGrey,
            textAlign: "center",
            padding: 0,
            width: "100%",
          }}
        >
          <Box display={{ xs: "none", md: "inherit", width: "100%" }}>
            <DesktopBar setShowLoginModal={setShowLoginModal} />
          </Box>
          <Box
            display={{ xs: "inherit", md: "none", width: "100%" }}
            style={{ padding: "12px" }}
          >
            <MobileBar setShowLoginModal={setShowLoginModal} />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};
