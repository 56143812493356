import Box from '@mui/material/Box';
import MUIButton from '@mui/material/Button';
import { Episode } from 'Types';
import { DarkBackground } from 'colors';
import styled from 'styled-components';
import { truncateString } from 'utilities';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const EpisodeTitle = styled.div`
  font-family: 'Roboto';
  font-size: 17px;
  color: white;
  display: flex;
  align-items: center;
`;

const ExpandButton = styled(MUIButton)`
  && {
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    width: fit-content;
    padding: 5px 10px 4px 7px;
    border-radius: 10px;
    cursor: pointer;
    color: white;
  }
`;

const VideoIframe = styled.iframe`
  height: 100%;
  width: 100%;
  margin: auto;
  border-radius: 10px;
  border: none;
`;

export const PlayerEpisodeBlock = ({
  id,
  rumbleEmbedLink,
  title,
  expanded,
  handleExpandEvent,
  index,
  isLastIndex,
}: Episode & {
  expanded: boolean;
  handleExpandEvent: React.Dispatch<React.SetStateAction<string>>;
  index: number;
  isLastIndex: boolean;
}) => {
  const mobileExpandedHeight = '300px';
  const desktopExpandedHeight = '500px';

  let borderRadius = '0px';

  if (index === 0) {
    borderRadius = '20px 20px 0px 0px';
  } else if (isLastIndex) {
    borderRadius = '0px 0px 20px 20px';
  }

  let formattedTitle = title;

  if (!expanded) {
    formattedTitle = truncateString(title, 50);
  }

  return (
    <Box
      onClick={() => handleExpandEvent(id)}
      height={{
        xs: expanded ? mobileExpandedHeight : '60px',
        md: expanded ? desktopExpandedHeight : '60px',
      }}
      style={{
        borderRadius,
        borderTop: index !== 0 ? 'white 1px solid' : '',
        cursor: 'pointer',
        width: 'auto',
        backgroundColor: DarkBackground,
        padding: '20px',
        display: 'flex',
        transition: 'all 0.3s ease-out',
        paddingTop: expanded ? '40px' : '30px',
        flexDirection: 'column',
        paddingBottom: expanded ? '20px' : '10px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        <EpisodeTitle>{formattedTitle}</EpisodeTitle>
        <ExpandButton onClick={() => handleExpandEvent(id)}>
          {expanded ? (
            <ExpandLessIcon style={{ fontSize: '25px' }} />
          ) : (
            <ExpandMoreIcon />
          )}
        </ExpandButton>
      </div>
      <div
        style={{
          display: expanded ? 'flex' : 'none',
          marginTop: expanded ? '20px' : '0px',
          height: '100%',
        }}
      >
        <VideoIframe src={rumbleEmbedLink} />
      </div>
    </Box>
  );
};
