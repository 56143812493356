import Fade from "@mui/material/Fade";
import { useCurrentUser } from "Hooks";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  ProfileDropDown,
  ProfileDropDownBackground,
  ProfileMenuItem,
} from "./elements";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";

interface Props {
  showProfileMenu: boolean;
  setShowProfileMenu: React.Dispatch<boolean>;
}

export const ProfileMenu: React.FC<Props> = ({
  setShowProfileMenu,
  showProfileMenu,
}) => {
  const { logout, currentUser } = useCurrentUser();
  const navigate = useNavigate();

  const handleLogout = () => {
    setShowProfileMenu(false);
    logout();
  };

  return (
    <>
      {currentUser && (
        <Fade in={showProfileMenu}>
          <ProfileDropDownBackground onClick={() => setShowProfileMenu(false)}>
            <div style={{ maxWidth: "790px", margin: "auto" }}>
              <ProfileDropDown onClick={e => e.stopPropagation()}>
                <ProfileMenuItem
                  style={{ marginTop: "15px" }}
                  onClick={() => navigate("/profile")}
                >
                  View profile
                  <PersonIcon style={{ height: "49px", width: "40px" }} />
                </ProfileMenuItem>
                <div
                  style={{
                    borderBottom: "1px solid black",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                />
                <ProfileMenuItem
                  style={{ color: "red" }}
                  onClick={handleLogout}
                >
                  Logout
                  <LogoutIcon style={{ marginLeft: "8px" }} />
                </ProfileMenuItem>
              </ProfileDropDown>
            </div>
          </ProfileDropDownBackground>
        </Fade>
      )}
    </>
  );
};
