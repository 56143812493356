import Box from "@mui/material/Box";
import React from "react";
import styled from "styled-components";
import backgroundBanner from "Images/background-banner.png";
import { DarkBackground } from "colors";

const MainHeading = styled.h1`
  font-size: inherit;
  color: white;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 500;
  margin: 0px;
`;

const SubHeading = styled.h2`
  text-transform: uppercase;
  font-family: "Roboto";
  color: white;
  font-size: inherit;
  font-weight: 200;
  margin: 0px;
`;

interface Props {
  mainHeading: string;
  subHeading?: string;
  smallerFont?: boolean;
}

export const GeneralPageHeader: React.FC<Props> = ({
  mainHeading,
  subHeading,
  smallerFont,
}) => (
  <>
    {/* ––– Background Image ––– */}
    <Box
      height={{ xs: "240px", md: "304px" }}
      width={"100%"}
      style={{
        position: "absolute",
        zIndex: -2,
        top: "56px",
        backgroundColor: DarkBackground,
      }}
    >
      <img
        style={{ objectFit: "cover", opacity: "35%" }}
        alt=""
        height="100%"
        width="100%"
        src={backgroundBanner}
      />
    </Box>

    {/* ––– HEADING ––– */}
    <div style={{ textAlign: "center" }}>
      <Box
        maxWidth={{ xs: "300px", sm: "700px" }}
        style={{ margin: "auto", marginBottom: 70, textAlign: "left" }}
      >
        <Box marginTop={{ xs: "130px", md: "210px" }}>
          <Box fontSize={{ xs: "25px", md: smallerFont ? "30px" : "40px" }}>
            <MainHeading>{mainHeading}</MainHeading>
          </Box>
          <Box fontSize={{ xs: "15px", md: "25px" }}>
            <SubHeading>{subHeading}</SubHeading>
          </Box>
        </Box>
      </Box>
    </div>
  </>
);
