import { createContext, useState } from "react";
import { User } from "Types";

interface ContextShape {
  currentUser: User | null;
  setCurrentUser: React.Dispatch<User | null> | null;
}

export const CurrentUserContext = createContext<ContextShape>({
  currentUser: null,
  setCurrentUser: null,
});

export const CurrentUserProvider: React.FC = ({ children }) => {
  const [currentUser, setCurrentUser] =
    useState<ContextShape["currentUser"]>(null);

  return (
    <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }}>
      {children}
    </CurrentUserContext.Provider>
  );
};
