import Box from "@mui/material/Box";
import { FormHeading, SectionWrapper } from "Components/FormComponents";
import React from "react";
import { UpdateInfoFields } from "./UpdateInfoFields";
import { UpdatePasswordFields } from "./UpdatePasswordFields";

export const UpdateInfoForm: React.FC = () => (
  <Box
    maxWidth={{ xs: "350px", md: "700px" }}
    style={{ paddingTop: "40px", margin: "auto" }}
  >
    <SectionWrapper
      style={{
        borderRadius: "12px",
        margin: "auto",
        paddingTop: "20px",
        marginBottom: "50px",
        textAlign: "center",
      }}
    >
      <FormHeading style={{ textAlign: "left", paddingLeft: "20px" }}>
        Update Personal Information
      </FormHeading>
      <UpdateInfoFields />
      <UpdatePasswordFields />
    </SectionWrapper>
  </Box>
);
