import { PaleGrey } from "../../colors";
import styled from "styled-components";

export const MainSynopsis = styled.div`
  max-width: 600px;
  font-family: "Roboto";
  text-align: left;
  font-size: 16px;
  margin-bottom: 20px;
  margin: auto;
  line-height: 22px;
`;

export const Bold = styled.div`
  font-weight: bold;
  display: inline;
`;

export const InfoBox = styled.div`
  min-height: 100px;
  width: 260px;
  padding: 20px;
  font-family: "Roboto";
  border-radius: 10px;
  margin: auto;
  margin-top: 15px;
`;

export const InfoBoxHeader = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 20px;
  padding: 5px 12px 4px 12px;
  letter-spacing: 0.7px;
  font-size: 13px;
  box-shadow: inset #8080804a 0px 0px 12px 0px;
  margin: auto;
  width: auto;
`;

export const SectionHeader = styled.h1`
  text-align: center;
  margin: auto;
  font-family: "Roboto";
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 25px;
`;

export const HostCard = styled.div`
  background-color: ${PaleGrey};
  /* max-height: 126px;
  width: 250px; */
  min-height: 126px;
  height: fit-content;
  width: 280px;
  border-radius: 12px;
  /* display: flex; */
  padding: 30px;
`;

export const HostSectionWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-direction: row;
  max-width: 720px;
  flex-flow: wrap;
  margin: auto;
  padding-bottom: 40px;
  font-family: "Roboto";
`;
