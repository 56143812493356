import Button from "@mui/material/Button";
import styled from "styled-components";

export const ProfileDropDown = styled.div`
  background-color: white;
  border-radius: 12px;
  position: relative;
  margin-right: 10px;
  margin-left: auto;
  height: 125px;
  width: 210px;
  top: 78px;
  box-shadow: #545454 0px 0px 11px 4px;
`;

export const ProfileMenuItem = styled(Button)`
  && {
    font-family: "Roboto";
    font-size: 18px;
    text-align: right;
    color: black;
    width: 160px;
    height: 40px;
    margin: auto;
    text-transform: none;
    border: none;
  }
`;

export const ProfileDropDownBackground = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #80808000;
  position: absolute;
`;
