import React from "react";

export const ZohoNewsletterSignUpForm: React.FC = () => (
  <div
    style={{ position: "relative", bottom: "-32px", height: "480px" }}
    dangerouslySetInnerHTML={{
      __html: `<script type="text/javascript" src="https://campaigns.zoho.com/js/zc.iframe.js"></script>
      <iframe frameborder="0" id="iframewin" width="100%" height="100%" src="https://zcsub-cmpzourl.maillist-manage.com/ua/Optin?od=11287ecbde08d9&zx=12e128afe&tD=1cdf376f6eb7b411&sD=1cdf376f6eb7c181"></iframe>`,
    }}
  />
);
