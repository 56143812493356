import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { truncateString } from 'utilities';

const CardWrapper = styled.div`
  height: 320px;
  width: 270px;
  box-shadow: #00000047 0px 0px 4px 1px;
  border-radius: 12px;
  cursor: pointer;
  border: 0.1px #0000007a solid;
`;

const BackgroundImage = styled.div<{ imageUrl: string }>`
  border-radius: 10px;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  height: 100%;
  width: 100%;
  filter: blur(1.5px) brightness(36%);
`;

const TitleText = styled.h3`
  font-family: 'Roboto';
  font-size: 35px;
  text-shadow: 0 0 6px #0000003b;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  padding: 30px 20px 10px;
  margin: 0px;
`;

const SubtitleText = styled.h4`
  font-family: 'Roboto';
  text-shadow: 0 0 6px #0000003b;
  padding: 10px 30px;
  font-size: 18px;
  color: white;
  font-weight: 100;
  margin: 0px;
`;

const EpisodeCountText = styled.p`
  font-family: 'Roboto';
  text-shadow: 0 0 6px #0000003b;
  font-size: 15px;
  text-transform: uppercase;
  max-width: 70px;
  color: white;
  margin-left: auto;
  padding-right: 18px;
  bottom: 10px;
`;

interface Props {
  seriesId: string;
  seriesTitle: string;
  seriesEpisodesLength: number;
  seriesCardImage: {
    url: string;
  };
  seriesSubtitle: string;
}

export const SeriesCard: React.ElementType<Props> = ({
  seriesCardImage,
  seriesTitle,
  seriesSubtitle,
  seriesEpisodesLength,
  seriesId,
}) => {
  const navigate = useNavigate();

  const titleCharLimit = seriesTitle.length < 38 ? seriesTitle.length : 36;

  return (
    <CardWrapper onClick={() => navigate(`/series/${seriesId}`)}>
      <BackgroundImage imageUrl={seriesCardImage.url} />
      <div style={{ position: 'relative', top: '-320px' }}>
        <TitleText style={{ color: 'white' }}>
          {truncateString(seriesTitle, titleCharLimit)}
        </TitleText>
        <div style={{ width: '100%', backgroundColor: '#0000005c' }}>
          <SubtitleText>{seriesSubtitle}</SubtitleText>
        </div>
        <div
          style={{
            width: '100%',
            textAlign: 'right',
          }}
        >
          <EpisodeCountText>
            {seriesEpisodesLength} part series
          </EpisodeCountText>
        </div>
      </div>
    </CardWrapper>
  );
};
