export type MerchOptions = "Sticker" | "Mug" | "Tee-shirt";
interface Merch {
  merchTitle: MerchOptions;
  merchPoints: number;
  merchImage: { src: string; height: string; width: string; marginTop: string };
  bgColor: "yellow" | "green" | "blue";
}

export const merchSelectionsData: Merch[] = [
  {
    merchTitle: "Sticker",
    merchPoints: 7,
    merchImage: {
      src: "https://trd-show.s3.amazonaws.com/merch-images/trd-sticker.png",
      height: "135px",
      width: "135px",
      marginTop: "-32px",
    },
    bgColor: "green",
  },
  {
    merchTitle: "Mug",
    merchPoints: 17,
    merchImage: {
      src: "https://trd-show.s3.amazonaws.com/merch-images/trd-mug.png",
      height: "150px",
      width: "150px",
      marginTop: "-37px",
    },
    bgColor: "blue",
  },
  {
    merchTitle: "Tee-shirt",
    merchPoints: 27,
    merchImage: {
      src: "https://trd-show.s3.amazonaws.com/merch-images/trd-teeshirt.png",
      height: "132px",
      width: "132px",
      marginTop: "-32px",
    },
    bgColor: "yellow",
  },
];
