import { useEffect } from "react";
import { FormStatus } from "Hooks";
import { Get_Current_User } from "Types";
import { Button } from "Components";

interface Args {
  getCurrentUserIsLoading: boolean;
  currentUser: Get_Current_User | null | undefined;
  setStatus: React.Dispatch<FormStatus>;
  registerAction?: () => any;
}

export const useLoginErrorHandling = ({
  getCurrentUserIsLoading,
  currentUser,
  setStatus,
  registerAction,
}: Args) => {
  useEffect(() => {
    if (!getCurrentUserIsLoading && currentUser) {
      // Handle error messages
      if ("message" in currentUser.getCurrentUser) {
        // Handle case where password is incorrect
        if (
          currentUser.getCurrentUser.message?.includes("auth/wrong-password")
        ) {
          setStatus({
            code: 404,
            message: "Your password is incorrect. Please try again.",
          });
          return;
        }

        if (
          currentUser.getCurrentUser.message?.includes("auth/user-not-found")
        ) {
          setStatus({
            code: 404,
            message: (
              <>
                {
                  "We couldn't find an account with that email. Click here to register a new account: "
                }
                <Button style={{ height: "30px" }} onClick={registerAction}>
                  Register
                </Button>
              </>
            ),
          });
          return;
        }

        // General display for all other errors
        setStatus({
          code: 404,
          message: currentUser.getCurrentUser.message || "",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCurrentUserIsLoading, currentUser]);
};
