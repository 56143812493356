import { Query_Get_Episodes_List } from "operations";
import { ApolloQueryResult, useLazyQuery } from "@apollo/client";
import { Get_Episodes_List, Get_Episodes_ListVariables } from "Types";

export type EpisodesListRefetch = (
  variables?: Partial<Get_Episodes_ListVariables> | undefined
) => Promise<ApolloQueryResult<Get_Episodes_List>>;

export const useGetEpisodesList = () => {
  const [
    getEpisodesList,
    { data, loading: getEpisodesListIsLoading, refetch },
  ] = useLazyQuery<Get_Episodes_List, Get_Episodes_ListVariables>(
    Query_Get_Episodes_List
  );

  return { getEpisodesList, getEpisodesListIsLoading, data, refetch };
};
