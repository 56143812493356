import Box from "@mui/material/Box";
import { FormHeading } from "Components/FormComponents";
import { useCurrentUser } from "Hooks";
import React, { useState } from "react";
import {
  CurrentPointsBox,
  ReferralDetailsSection,
  MainWrapper,
  InputLabel,
  ReferralCodeContainer,
  ReferralLink,
  ShareButton,
} from "./elements";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Fade from "@mui/material/Fade";
import gabLogo from "Images/gab-logo.png";
import gettrIcon from "Images/gettr-icon.png";
import RefreshIcon from "@mui/icons-material/Refresh";

export const ReferralInfoSections: React.FC = () => {
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const { currentUser, refetch } = useCurrentUser();

  if (!currentUser) return null;

  const codeLink = `https://trdshow.net/referral?code=${currentUser.referralCode}`;

  const promoText =
    "The Reformed Dissenters show combines current events with theology and principled literature to provide you with an informative resource to kickstart your curiosity and develop a profoundly Biblical worldview. Join the network, today (and start earning points towards free merch!):";

  const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${codeLink}`;
  const twitterShareLink = `https://twitter.com/intent/tweet?text=${promoText} ${codeLink}`;
  const gabShareLink = `https://gab.com/compose?text=${promoText} ${codeLink}`;
  const gettrShareLink = `https://gettr.com/share?url=${codeLink}&text=${promoText}`;

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(codeLink);

    setShowCopiedMessage(true);

    setTimeout(() => {
      setShowCopiedMessage(false);
    }, 4000);
  };

  return (
    <MainWrapper flexDirection={{ xs: "column", md: "row" }}>
      <Box
        margin={{ xs: "auto", md: "0px" }}
        style={{ flexDirection: "column" }}
      >
        <FormHeading style={{ textAlign: "left" }}>Referral Points</FormHeading>
        <CurrentPointsBox>
          <RefreshIcon
            onClick={() => refetch && refetch()}
            style={{
              position: "relative",
              top: "10px",
              right: "-65px",
              cursor: "pointer",
            }}
          />
          <div
            style={{
              fontSize: "13px",
              fontWeight: "bold",
              letterSpacing: "0px",
              marginTop: "24px",
            }}
          >
            Current Points
          </div>
          <div
            style={{ fontSize: "25px", fontWeight: "bold", marginTop: "5px" }}
          >{`${currentUser.referralPoints}pts`}</div>
        </CurrentPointsBox>
      </Box>
      <Box
        width={{ xs: "100%", md: "365px" }}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          style={{ marginRight: "auto" }}
          marginLeft={{ xs: "auto", md: "0px" }}
        >
          <FormHeading style={{ textAlign: "left", marginLeft: "0px" }}>
            Share Your Code
          </FormHeading>
        </Box>
        <ReferralDetailsSection>
          <InputLabel>Your referral code link</InputLabel>
          <ReferralCodeContainer>
            <ReferralLink to={`/referral?code=${currentUser.referralCode}`}>
              {codeLink}
            </ReferralLink>
            <ContentCopyIcon
              onClick={handleCopyLink}
              style={{
                height: "14px",
                width: "20px",
                right: "-25px",
                top: "2px",
                position: "relative",
                cursor: "pointer",
              }}
            />
            <Fade in={showCopiedMessage}>
              <div
                style={{
                  backgroundColor: "white",
                  fontSize: "10px",
                  position: "relative",
                  width: "fit-content",
                  borderRadius: "8px",
                  padding: "5px",
                  right: "-196px",
                  top: "20px",
                }}
              >
                Link copied to clipboard
              </div>
            </Fade>
          </ReferralCodeContainer>
          <InputLabel>Share on social</InputLabel>
          <div
            style={{
              flexDirection: "row",
              justifyContent: "left",
              display: "flex",
            }}
          >
            <ShareButton
              style={{ textAlign: "center", padding: "2px" }}
              target="_blank"
              href={gettrShareLink}
            >
              <img
                src={gettrIcon}
                style={{
                  height: "18px",
                  width: "20px",
                  margin: "auto",
                  marginTop: "3px",
                }}
                alt="Gettr Logo"
              />
            </ShareButton>
            <ShareButton
              style={{ textAlign: "left" }}
              target="_blank"
              href={gabShareLink}
            >
              <img
                src={gabLogo}
                style={{ height: "16px", width: "24px", marginTop: "4px" }}
                alt="Gab Logo"
              />
            </ShareButton>
            <ShareButton
              style={{ textAlign: "left" }}
              target="_blank"
              href={facebookShareLink}
            >
              <FacebookIcon
                style={{
                  color: "#3c3cff",
                }}
              />
            </ShareButton>
            <ShareButton
              style={{ textAlign: "left" }}
              target="_blank"
              href={twitterShareLink}
            >
              <TwitterIcon
                style={{
                  color: "#4accf7",
                }}
              />
            </ShareButton>
          </div>
        </ReferralDetailsSection>
      </Box>
    </MainWrapper>
  );
};
