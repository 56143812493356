import { Query_Get_Current_User } from "operations";
import { useLazyQuery } from "@apollo/client";
import { Get_Current_User, Get_Current_UserVariables } from "Types";

export const useGetCurrentUser = () => {
  const [getCurrentUser, { data, loading: getCurrentUserIsLoading }] =
    useLazyQuery<Get_Current_User, Get_Current_UserVariables>(
      Query_Get_Current_User,
      { fetchPolicy: "network-only" }
    );

  return { getCurrentUser, getCurrentUserIsLoading, data };
};
