import React, { useState } from "react";
import {
  Button,
  Navbar,
  PicturePageHeader,
  PlatformLinks,
  Link,
  ContactForm,
  Sitemap,
  ZohoNewsletterSignUpForm,
  ReferralProgramAd,
  MerchStoreAd,
  LoginRegisterModal,
  LatestEpisodeBox,
} from "Components";
import { DefaultPageWrapper } from "Components";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import styled from "styled-components";
import { useCurrentUser } from "Hooks";

const Bold = styled.div`
  font-weight: bold;
  display: inline;
`;

export const Homepage: React.FC = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);

  const { currentUser } = useCurrentUser();

  const handleModalClose = () => setShowLoginModal(false);

  return (
    <>
      <LoginRegisterModal
        showModal={showLoginModal}
        handleClose={handleModalClose}
      />
      <DefaultPageWrapper bottomPaddingFalse>
        <Navbar />
        <PicturePageHeader />
        <div style={{ maxWidth: "700px", margin: "auto" }}>
          <Box width={{ xs: "330px", md: "100%" }} style={{ margin: "auto" }}>
            <iframe
              title="spotify-player"
              src="https://open.spotify.com/embed/show/4DonJKILKYvWnvtRlMfics"
              width="100%"
              height="160"
              frameBorder="0"
              allowTransparency={true}
              allow="encrypted-media"
            />
          </Box>
        </div>
        <div style={{ marginTop: "50px" }}>
          <LatestEpisodeBox />
        </div>
        {!currentUser && (
          <div style={{ marginTop: "60px" }}>
            <Box
              maxWidth={{ xs: "320px", md: "500px" }}
              style={{
                textAlign: "left",
                fontFamily: "Roboto",
                margin: "auto",
                marginBottom: "30px",
                fontSize: "18px",
              }}
            >
              Get access to extended Reformed Dissenters features and help
              support the show (for free!).
            </Box>
            <Button
              onClick={() => setShowLoginModal(true)}
              style={{ fontSize: "17px", width: "250px" }}
            >
              Sign Up For a Reformed Dissenters Account
            </Button>
          </div>
        )}
        <div style={{ marginTop: "40px" }}>
          <MerchStoreAd />
        </div>
        <Grid
          container
          style={{
            maxWidth: "800px",
            margin: "auto",
            marginTop: "140px",
            marginBottom: "130px",
          }}
        >
          <Grid item md={4} xs={12}>
            <Box textAlign={{ xs: "center", md: "left" }}>
              <p
                style={{
                  fontFamily: "Roboto",
                  maxWidth: "280px",
                  paddingTop: "20px",
                  fontSize: 16,
                  lineHeight: 1.3,
                  letterSpacing: 0.8,
                  margin: "0px auto 20px",
                }}
              >
                <Bold>The Reformed Dissenters</Bold> show combines{" "}
                <Bold>current events </Bold>
                with <Bold>theology </Bold> and{" "}
                <Bold>principled literature </Bold>
                to provide you with an informative resource to kickstart your
                curiosity and develop a profoundly Biblical worldview.
              </p>
              <Link to="/about">
                <Button style={{ margin: "auto auto 40px" }}>Learn more</Button>
              </Link>
            </Box>
          </Grid>
          <Grid item md={8} xs={12}>
            <Box paddingLeft={{ md: "40px" }}>
              <PlatformLinks />
            </Box>
          </Grid>
        </Grid>
        <ReferralProgramAd />
        <Box display={{ md: "block" }}>
          <ZohoNewsletterSignUpForm />
        </Box>
        <ContactForm />
        <Sitemap />
      </DefaultPageWrapper>
    </>
  );
};
