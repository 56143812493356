import styled from "styled-components";
import { Link } from "Components";
import Button from "@mui/material/Button";

export const TopSynopsis = styled.div`
  font-family: "Roboto";
  font-size: 17px;
  margin: auto;
  padding: 10px 30px;
  padding-top: 30px;
  text-align: center;
  line-height: 26px;
`;

export const SectionHeader = styled.h1`
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  text-align: inherit;
  margin-top: 34px;
  margin-bottom: 24px;
`;

export const ColorBoxesWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-flow: wrap;
  padding-right: 10px;
  padding-left: 10px;
`;

export const ColorBoxText = styled.h2`
  font-size: 14px;
  font-weight: bold;
  font-family: "Roboto";
  text-align: center;
`;

export const MerchName = styled.p`
  font-family: "Roboto";
  font-size: 14px;
  text-align: center;
`;

export const ReferralFormHeader = styled.div`
  font-size: 34px;
  font-family: "Roboto";
  margin-bottom: 60px;
  letter-spacing: 0.5px;
  font-weight: 500;
  word-spacing: 3px;
`;

export const ReferralFormDescriptiveText = styled.div`
  font-size: 20px;
  max-width: 310px;
  text-align: center;
  margin: auto;
  margin-bottom: 20px;
`;

export const ErrorText = styled.div`
  color: #ff9191;
  font-size: 15px;
  margin-bottom: 10px;
`;

export const CodeDescText = styled.div`
  font-family: "Roboto";
  font-weight: 100;
  display: inline;
  font-size: 25px;
`;

export const SummationText = styled.div`
  font-size: inherit;
  font-family: "Roboto";
  text-align: center;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-right: 30px;
  padding-left: 30px;
  max-width: 450px;
  font-weight: 100;
`;

export const ReferralCodeText = styled(SummationText)`
  background-color: #303030;
  color: white;
  width: fit-content;
  padding: 14px;
  border-radius: 10px;
  font-size: 25px;
  display: inline;
  margin-left: 10px;
`;

export const ReferralCodeLinkWrapper = styled.div`
  text-align: center;
  width: fit-content;
  height: 30px;
  background-color: lightgray;
  border: black solid 1px;
  border-radius: 10px;
  font-family: "Roboto";
  font-size: 18px;
  margin: auto;
  margin-top: 60px;
  padding: 12px;
  display: flex;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  color: black;
  display: flex;

  &:hover {
    text-decoration: underline;
  }
`;

export const CopyButtonWrapper = styled(Button)`
  width: 40px;
  height: fit-content;
  right: -14px;
  margin-left: -16px;
  padding: 0px;
  color: #303030;
  display: flex;
`;
