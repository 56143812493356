import { StyledInput } from "Components/FormComponents";
import { SkyBlue } from "../../colors";
import styled from "styled-components";

export const FormField = styled(StyledInput)<{ longWidth?: boolean }>`
  width: ${({ longWidth }) => (longWidth ? "330px" : "280px")};
  margin-top: 10px;
`;

export const BlueText = styled.div`
  font-family: "Roboto";
  font-size: 16px;
  color: ${SkyBlue};
  cursor: pointer;
  margin-top: 25px;
`;
