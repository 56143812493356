import { Query_Get_Series_List } from "operations";
import { ApolloQueryResult, useLazyQuery } from "@apollo/client";
import { Get_Series_List, Get_Series_ListVariables } from "Types";

export type SeriesListRefetch = (
  variables?: Partial<Get_Series_ListVariables> | undefined
) => Promise<ApolloQueryResult<Get_Series_List>>;

export const useGetSeriesList = () => {
  const [getSeriesList, { data, loading: getSeriesListIsLoading, refetch }] =
    useLazyQuery<Get_Series_List, Get_Series_ListVariables>(
      Query_Get_Series_List
    );

  return { getSeriesList, getSeriesListIsLoading, data, refetch };
};
