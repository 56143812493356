/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum EpisodeType {
  CURRENT_EVENTS = "CURRENT_EVENTS",
  DISCUSSION_TOPIC = "DISCUSSION_TOPIC",
  LITERATURE_EPISODE = "LITERATURE_EPISODE",
}

export enum SearchByOptions {
  tags = "tags",
  titleDescription = "titleDescription",
}

export interface AwardPointToReferrerInput {
  referralCode?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

export {}