import { Query_Get_Latest_Episode } from "operations";
import { useLazyQuery } from "@apollo/client";
import { Get_Latest_Episode } from "Types";

export const useGetLatestEpisode = () => {
  const [
    getLatestEpisode,
    { data, loading: getLatestEpisodeIsLoading, refetch },
  ] = useLazyQuery<Get_Latest_Episode>(Query_Get_Latest_Episode);

  return { getLatestEpisode, getLatestEpisodeIsLoading, data, refetch };
};
