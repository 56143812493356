import styled from "styled-components";
import Input from "@mui/material/Input";

export const ReferralFormInput = styled(Input)`
  && {
    border: 2px solid black;
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
    margin-bottom: 15px;
    max-width: 280px;
    width: 100%;
  }
`;
